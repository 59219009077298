import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import baseModel from '../../ressources/user/baseModel';
import newModel from '../../ressources/user/newModel';
import * as Actions from '../../redux/user/actions';
import Editor from './Editor';
import appConfig from '../../appConfig';
import axios from '../../axiosConfig';
import utils from '../../helpers/utils';

const API_URL = appConfig.api_url;

const mapStateToProps = state => {
  return { items: state.users.collection };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchChunk: start => dispatch(Actions.fetchChunk(start)),
    updItem: (year, item) => dispatch(Actions.updItem(year, item)),
    delItem: id => dispatch(Actions.delItem(id)),
  };
};

class Single extends Component {
  static defaultProps = {
    items: [],
  };
  constructor(props) {
    super(props);
    // this.fetch();
    this.state = {
      user: null,
      wgenProfile: null,
    };

    this.fetchWsProfile();
  }
  fetch() {
    this.props.fetchChunk();
  }
  fetchWsProfile() {
    const item = this.getItemInEdition();
    axios
      .get(`${API_URL}/users/search/wgen/${item._id}`)
      .then(response => {
        let res = response.data;
        this.setState({
          user: res.user,
          wgenProfile: res.wgenProfile,
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  }
  toggleAmbassador() {
    const item = this.getItemInEdition();
    axios
      .get(`${API_URL}/users/search/toggle-ambassador/${item._id}`)
      .then(response => {
        let res = response.data;
        this.setState({
          user: res.user,
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  }
  getItemInEdition() {
    const { items, match } = this.props;
    const { _id } = match.params;
    const id = _id;

    let base;
    if (id === 'add') {
      base = newModel;
    } else {
      base = baseModel;
    }

    // ITEM
    console.log(items);
    const item = items.filter(item => item._id === _id)[0] || base;
    console.log(item, 'item');
    return item;
  }
  render() {
    const item = this.getItemInEdition();
    const { match, readOnlyMode, deleteMode, isDuplicata } = this.props;
    const { user, wgenProfile } = this.state;

    if (isDuplicata) {
      item._id = 'new';
    }

    let slugLevels = match.url.split('/');
    slugLevels.pop();
    if (
      slugLevels.slice(-1) == 'edit' ||
      slugLevels.slice(-1) == 'delete' ||
      slugLevels.slice(-1) == 'duplicate'
    ) {
      slugLevels.pop();
    }
    const back = slugLevels.join('/');

    return (
      <>
        {item && (
          <Editor
            noDeletionAllowed
            item={item}
            back={back}
            readOnlyMode={readOnlyMode}
            // deleteMode={deleteMode}
          />
        )}
        <Row>
          <div className="col-lg-12">
            <div className="card mb-3">
              <div className="card-header">
                Profile info
                {user?.profile.isAmbassador ? (
                  <span
                    className="btn btn-primary float-right"
                    onClick={() => {
                      this.toggleAmbassador();
                    }}
                  >
                    Remove user form ambassador
                  </span>
                ) : (
                  <span
                    className="btn btn-success float-right"
                    onClick={() => {
                      this.toggleAmbassador();
                    }}
                  >
                    Add user to ambassador
                  </span>
                )}
              </div>
              <div className="card-body">
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">Email</label>
                    <div>
                      <span>{user?.email}</span>
                      {user?.profile.isAmbassador && (
                        <span className="badge badge-success">Ambassador</span>
                      )}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">Firstname</label>
                    <div>
                      <span>{user?.profile?.name}</span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">Sexe</label>
                    <div>
                      <span>{user?.profile?.sexe}</span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">Birthdate</label>
                    <div>
                      <span>{utils.formatDate(user?.profile?.birthdate)}</span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">createdAt</label>
                    <div>
                      <span>{utils.formatDate(user?.profile?.createdAt)}</span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">updatedAt</label>
                    <div>
                      <span>{utils.formatDate(user?.profile?.updatedAt)}</span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col">
                    <label htmlFor="role">Last connexion</label>
                    <div>
                      <span>{utils.formatDate(user?.lastConnection)}</span>
                    </div>
                  </div>
                </Row>
                {/* <Row> 
                  <div className="col-lg-12">
                    {user && <>{utils.toJSON(user)}</>}
                  </div>
                </Row> */}
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-lg-12">
            <div className="card mb-3">
              <div className="card-header">Profile raw data for WS</div>
              <div className="card-body">
                <Row>
                  <div className="col-lg-12">
                    {wgenProfile && <>{utils.toJSON(wgenProfile)}</>}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Single));
