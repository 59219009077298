import React, { Component } from 'react';
// import utils from '../../helpers/utils';
import { withRouter } from 'react-router-dom';
import { StatResume } from '../../components/Field';
import { connect } from 'react-redux';
import * as Actions from '../../redux/equipment/actions';
import appConfig from '../../appConfig';

const nbItemPerPage = appConfig.settings.nbItemPerPage;

const mapStateToProps = state => {
  return {
    items: state.equipments.collection,
    count: state.equipments.count,
    loggedInUser: state.app.loggedInUser,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchChunk: (start, limit, textSearch) =>
      dispatch(Actions.fetchChunk(start, limit, textSearch)),
  };
};
class EventStatResume extends Component {
  constructor(props) {
    super(props);
    this.fetch();
  }
  fetch() {
    this.props.fetchChunk(0, nbItemPerPage);
  }
  render() {
    let { items, count, loggedInUser } = this.props;
    items = items || [];
    // items = items.filter(i => i.is_active);

    // const stat = {
    //   full: items.filter(item => item.role === 'Full Control'),
    //   partial: items.filter(item => item.role === 'Partial Control'),
    //   read: items.filter(item => item.role === 'Read Only'),
    // };

    // const stats = [
    //   stat.full.length.toString() + ' Full Control',
    //   stat.partial.length.toString() + ' Partial Control',
    //   stat.read.length.toString() + ' Read Only',
    // ];

    let linkTo = null;
    if (loggedInUser.role === appConfig.role[2]) {
      linkTo = '/docs';
    }

    return (
      <StatResume
        label="Equipments"
        value={count}
        icon={<i className="fas fa-dumbbell" />}
        stats={[]}
        linkTo={linkTo}
      />
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventStatResume),
);
