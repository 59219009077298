import axios from 'axios';
import appConfig from './appConfig';

const TOKEN_KEY = appConfig.settings.tokenKey || 'app-key:api_token';
var oAxios = axios.create();

oAxios.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem(TOKEN_KEY);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default oAxios;
