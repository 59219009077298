import React, { Component } from 'react';
import Avatar from './Avatar';

class Admin extends Component {
  static defaultProps = {
    isActive: true,
  };
  render() {
    const { avatar, email, roles, size, isActive } = this.props;

    const unactiveClassname = isActive ? '' : 'disabled';

    return (
      <div
        className={'user-card d-flex align-items-center ' + unactiveClassname}
      >
        <Avatar avatar={avatar} size={size} />
        <div className="user-info">
          <h5 className="user-name mb-0">{email}</h5>
          <div className="user-role">{roles}</div>
        </div>
      </div>
    );
  }
}

export default Admin;
