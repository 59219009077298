import appConfig from '../../appConfig';
import ActionTypes from './actionTypes';
import axios from '../../axiosConfig';

const API_URL = appConfig.api_url;

// action message
export const messageClear = () => ({
  type: ActionTypes.ACTION_MESSAGE_CLEAR,
  payload: { message: '', code: 0 },
});
export const messageSuccess = message => ({
  type: ActionTypes.ACTION_MESSAGE_CLEAR,
  payload: { message: message, code: 200 },
});
export const messageInfo = message => ({
  type: ActionTypes.ACTION_MESSAGE_CLEAR,
  payload: { message: message, code: 1000 },
});
export const messageError = (message, error) => ({
  type: ActionTypes.ACTION_MESSAGE_ERROR,
  payload: { message: message, error: error, code: 500 },
});

// loginWithToken
export const loginWithToken = token => dispatch => {
  dispatch(loginWithTokenBegin());
  axios({
    method: 'get',
    url: `${API_URL}/auth/admin/verify`,
    data: {},
    // headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then(response => {
      let data = response.data;
      dispatch(loginSuccess(data));
    })
    .catch(error => dispatch(loginWithTokenError(error)));
};
export const loginWithTokenBegin = () => ({
  type: ActionTypes.LOGIN_WITH_TOKEN_BEGIN,
  payload: null,
});
export const loginWithTokenSuccess = data => ({
  type: ActionTypes.LOGIN_WITH_TOKEN_SUCCESS,
  payload: data,
});
export const loginWithTokenError = error => ({
  type: ActionTypes.LOGIN_WITH_TOKEN_ERROR,
  payload: error,
});

// login
export const login = (email, pwd) => dispatch => {
  dispatch(loginBegin());
  axios({
    method: 'post',
    url: `${API_URL}/auth/admin/login`,
    data: {
      email: email,
      password: pwd,
    },
  })
    .then(response => {
      let data = response.data;
      dispatch(loginSuccess(data));
    })
    .catch(error => dispatch(loginError(error)));
};
export const loginBegin = () => ({
  type: ActionTypes.LOGIN_BEGIN,
  payload: null,
});
export const loginSuccess = data => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: data,
});
export const loginError = error =>
  messageError(
    'Wrong username / password, please check your credentials and try again',
    error,
  );

// refresh token
export const refreshToken = token => dispatch => {
  dispatch(refreshTokenBegin());
  axios({
    method: 'post',
    url: `${API_URL}/auth/refresh`,
    data: {},
  })
    .then(response => {
      let data = response.data;
      dispatch(loginSuccess(data));
    })
    .catch(error => dispatch(refreshTokenError(error)));
};
export const refreshTokenBegin = () => ({
  type: ActionTypes.REFRESH_TOKEN_BEGIN,
  payload: null,
});
export const refreshTokenSuccess = data => ({
  type: ActionTypes.REFRESH_TOKEN_SUCCESS,
  payload: data,
});
export const refreshTokenError = error => ({
  type: ActionTypes.REFRESH_TOKEN_ERROR,
  payload: error,
});

//logout
export const logout = () => ({ type: ActionTypes.LOGOUT, payload: null });

export const search = searchValue => ({
  type: ActionTypes.SEARCH,
  payload: searchValue,
});

export const exportEvents = exportSetting => dispatch => {
  dispatch({ type: ActionTypes.EXPORT, payload: 'in-waiting' });
  axios({
    method: 'post',
    url: `${API_URL}/events/export`,
    responseType: 'blob', // important
    data: {
      format: exportSetting.format,
      event_ids: exportSetting.event_ids,
      keys: exportSetting.keys,
    },
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    dispatch({ type: ActionTypes.EXPORT, payload: url });
    // setTimeout(()=>{
    //   dispatch(({ type: ActionTypes.EXPORT, payload: url }))
    // },3000)
  });
};

//  App Settings
export const updSettings = (user, settings) => dispatch => {
  // then call backend to store it
  user.settings = settings;
  const data = user;

  // axios({
  //   method: 'put',
  //   url: `${API_URL}/users/${user.id}`,
  //   data,
  // })
  //   .then(response => {
  //     let data = response.data.data;
  //     dispatch({ type: ActionTypes.UPD_SETTINGS, payload: data.settings });
  //   })
  //   .catch(error => {
  //     dispatch(
  //       messageError(
  //         'Oops Something goes wrong. Please retry in a few seconds',
  //       ),
  //     );
  //   });

  dispatch({ type: ActionTypes.UPD_SETTINGS, payload: data.settings });
};

export const enableSidebarConfirmation = () => ({
  type: ActionTypes.ENABLE_SIDEBAR_CONFIRMATION,
  payload: null,
});
export const disableSidebarConfirmation = () => ({
  type: ActionTypes.DISABLE_SIDEBAR_CONFIRMATION,
  payload: null,
});
