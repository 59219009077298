import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class BackLink extends Component {
  goBack() {
    const { history } = this.props;
    history.goBack();
  }
  render() {
    return (
      <span
        onClick={() => {
          this.goBack();
        }}
        className="back-button"
      >
        {this.props.children}
      </span>
    );
  }
}

export default withRouter(BackLink);
