import React, { Component } from 'react';

class Input extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
  };
  updateData(value) {
    const { updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  render() {
    const { name, type, className } = this.props;
    let { value } = this.props;
    value = value || '';

    return (
      <input
        type={type}
        className={'form-control ' + className}
        name={name}
        value={value}
        onChange={e => {
          this.updateData(e.target.value);
        }}
      />
    );
  }
}

export default Input;
