import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Input from './Input';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import utils from '../../helpers/utils';
registerLocale('en', en);

class DatetimePicker extends Component {
  // static defaultProps = {
  //  timeInputHidden: true
  // }
  constructor(props) {
    super(props);
    this.state = {
      timeInputHidden: true,
    };
  }
  componentDidUpdate(prevProps) {
    let { date, minDate } = this.props;
    const dateObj = date ? new Date(date) : null;
    const minDateObj = minDate ? new Date(minDate) : null;

    if (
      dateObj &&
      minDateObj &&
      prevProps.minDate !== minDate &&
      minDateObj > dateObj
    ) {
      this.updDate(minDateObj);
    }
  }
  toggleTime() {
    this.setState({
      timeInputHidden: !this.state.timeInputHidden,
    });
  }
  updDate(d) {
    const { updateEditorData } = this.props;
    const hourTime = this.buildHourTime(d);

    if (d instanceof Date) {
      const splitTime = hourTime.split(':');
      if (splitTime.length >= 2) {
        d.setHours(splitTime[0]);
        d.setMinutes(splitTime[1]);
      }

      updateEditorData && updateEditorData(d.toISOString());
    } else {
      updateEditorData && updateEditorData(null);
    }
  }
  updHour(h) {
    const { updateEditorData } = this.props;
    const { date } = this.props;

    let d = new Date(date);
    const splitTime = h.split(':');
    if (splitTime.length <= 2) {
      d.setHours(splitTime[0]);
      d.setMinutes(splitTime[1]);

      updateEditorData && updateEditorData(d.toISOString());
    }
  }
  buildHourTime(date) {
    if (date instanceof Date) {
      return utils.toLocaleTimeString(date);
    }
    return '00:00';
  }
  render() {
    let { date, minDate, disabled } = this.props;
    let { timeInputHidden } = this.state;
    date = date ? new Date(date) : null;
    minDate = minDate ? new Date(minDate) : null;

    const hourTime = this.buildHourTime(date);

    return (
      <div>
        <div className="input-group">
          <div style={{ flexGrow: 1, position: 'relative' }}>
            <DatePicker
              disabled={disabled}
              showYearDropdown
              locale="en"
              dateFormat="dd/MM/yyyy"
              dateFormatCalendar="MMMM"
              selected={date}
              minDate={minDate}
              onChange={d => {
                this.updDate(d);
              }}
            />
            {!disabled && date && (
              <span
                onClick={() => {
                  this.updDate(null);
                }}
                className="clear-date"
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </span>
            )}
          </div>
          <div className="ml-1">
            <Button
              disabled={disabled}
              className="btn btn-light"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.toggleTime();
              }}
            >
              {' '}
              <i className="far fa-clock"></i>{' '}
            </Button>
          </div>
        </div>

        {!timeInputHidden && (
          <Input
            disabled={disabled}
            type="text"
            name="time"
            className="mt-1"
            value={hourTime}
            updateEditorData={data => {}}
          />
        )}
      </div>
    );
  }
}

export default DatetimePicker;
