import React, { Component } from 'react';
import { Pill } from '../Button';

class Pills extends Component {
  render() {
    const { items, updateEditorData } = this.props;

    return (
      <>
        {items &&
          items.map((item, i) => (
            <Pill
              key={i}
              items={items}
              item={item}
              updateEditorData={updateEditorData}
            >
              {typeof item === 'string' && <>{item}</>}

              {typeof item !== 'string' && <>{item.name || item.code}</>}
            </Pill>
          ))}
      </>
    );
  }
}

export default Pills;
