import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../redux/user/actions';
import columnDef from '../../ressources/user/columnDefinitions';
import { Table } from '../../components/Table';
import appConfig from '../../appConfig';

const nbItemPerPage = appConfig.settings.nbItemPerPage;
const colState = columnDef.slice(0, 2);

const mapStateToProps = state => {
  return {
    searchValue: state.app.searchValue,
    items: state.users.collection,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchChunk: (start, limit, textSearch) =>
      dispatch(Actions.fetchChunk(start, limit, textSearch)),
    delItem: id => dispatch(Actions.delItem(id)),
  };
};

class LastUpdatedUserTable extends Component {
  constructor(props) {
    super(props);

    this.props.fetchChunk(0, nbItemPerPage);
  }
  delItem(i) {
    this.props.delItem(i);
  }
  render() {
    const { items, searchValue, clickItem, header } = this.props;
    let itemsToShow = items || [];

    let hasFetch = false;
    if (itemsToShow.length > 0) {
      hasFetch = true;
      itemsToShow = itemsToShow.slice(0, 5);
    }

    return (
      <Table
        isSticky={false}
        inLoading={!hasFetch}
        searchInput={searchValue}
        column={colState}
        items={itemsToShow}
        clickItem={item => {
          // clickItem(item);
        }}
        title={<h3>Athletes</h3>}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LastUpdatedUserTable);
