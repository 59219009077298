import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Actions from '../../../redux/user/actions';
import columnDef from '../../../ressources/user/columnDefinitions';
import { Table } from '../../../components/Table';
import appConfig from '../../../appConfig';
import {
  Modal,
  Dropdown,
  ConditionalTooltip,
} from '../../../components/Button';
import { enableSidebarConfirmation } from '../../../redux/app/actions';

const nbItemPerPage = appConfig.settings.nbItemPerPage;
const colState = [
  {
    label: 'Profile',
  },
  {
    label: 'Workouts',
  },
  {
    label: 'Actions',
    meta: 'User',
  },
];

const mapStateToProps = state => {
  return {
    loggedInUser: state.app.loggedInUser,
    searchValue: state.app.searchValue,
    items: state.users.collection,
    count: state.users.count,
    currentPage: state.users.tablePage,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setTablePage: page => dispatch(Actions.setTablePage(page)),
    clearCollection: () => dispatch(Actions.clearCollection()),
    fetchChunk: (start, limit, textSearch) =>
      dispatch(Actions.fetchChunk(start, limit, textSearch)),
    delItem: id => dispatch(Actions.delItem(id)),
  };
};

class TableCr extends Component {
  constructor(props) {
    super(props);

    this.props.clearCollection();
    const start = (props.currentPage - 1) * nbItemPerPage;
    this.props.fetchChunk(start, nbItemPerPage, props.searchValue);
  }
  setCurrentPage(page) {
    this.props.setTablePage(page);
  }
  onPageClick(pageToGo) {
    // // fetch items for page
    const start = (pageToGo - 1) * nbItemPerPage;
    this.props.fetchChunk(start, nbItemPerPage, this.props.searchValue);

    this.setCurrentPage(pageToGo);
  }
  delItem(i) {
    this.props.delItem(i);
  }
  resetTable() {
    this.props.clearCollection();
    this.setCurrentPage(1);
  }
  componentDidUpdate(prevProps, prevState) {
    const { searchValue } = this.props;
    if (prevProps.searchValue !== searchValue) {
      if (searchValue && searchValue !== '') {
        console.log('fetch with search');
        this.resetTable();
        this.props.fetchChunk(0, nbItemPerPage, searchValue);
      } else {
        this.resetTable();
        this.props.fetchChunk(0, nbItemPerPage);
      }
    }
  }
  render() {
    const { loggedInUser, items, count, searchValue, clickItem, currentPage } =
      this.props;

    let itemsToShow = items || [];

    let hasFetch = false;
    if (itemsToShow.length > 0) {
      hasFetch = true;
      itemsToShow = itemsToShow;
    }

    return (
      <>
        <div className="card">
          <div className="card-header"> Latest entries</div>
          <div className="card-body">
            <Table
              isCard={false}
              pagination={false}
              paginationNbItemPerPage={10}
              paginationItemCount={10}
              currentPage={1}
              // pagination
              // paginationNbItemPerPage={nbItemPerPage}
              // paginationItemCount={count}
              // currentPage={currentPage}
              // onPageClick={page => {
              //   this.onPageClick(page);
              // }}
              // isSticky={true}
              inLoading={!hasFetch}
              // searchInput={searchValue}
              column={colState}
              items={itemsToShow}
              clickItem={item => {
                clickItem(item);
              }}
              // actions={
              //   <div className="actions">
              //     {loggedInUser.roles.includes(appConfig.role[0]) && (
              //       <ConditionalTooltip tooltipText="Add">
              //         <NavLink
              //           className="btn btn-lg btn-primary mr-1"
              //           to={'/docs/add'}
              //         >
              //           <i className="fas fa-plus"></i>
              //         </NavLink>
              //       </ConditionalTooltip>
              //     )}
              //   </div>
              // }
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableCr);
