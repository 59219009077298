import React, { Component } from 'react';
import Avatar from './Avatar';
import { NavLink, withRouter } from 'react-router-dom';

class DocActions extends Component {
  static defaultProps = {
    isActive: true,
    delItem: null,
  };
  componentUrl() {
    const { history } = this.props;
    return history.location.pathname;
  }
  render() {
    const {
      avatar,
      name,
      role,
      size,
      isActive,
      data,
      delItem,
      editItem,
      rowIndex,
    } = this.props;

    return (
      <div className="table-actions">
        <div className="ml-auto">
          {editItem && (
            <a
              onClick={() => {
                editItem(rowIndex);
              }}
            >
              <i className="far fa-edit"></i>
            </a>
          )}

          {!editItem && (
            <NavLink to={`${this.componentUrl()}/duplicate`}>
              <i className="far fa-clone"></i>
            </NavLink>
          )}

          {!editItem && (
            <NavLink to={`${this.componentUrl()}/edit`}>
              <i className="far fa-edit"></i>
            </NavLink>
          )}

          {delItem && (
            <a
              onClick={() => {
                delItem(rowIndex);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </a>
          )}

          {!delItem && (
            <NavLink to={`${this.componentUrl()}/delete`}>
              <i className="far fa-trash-alt"></i>
            </NavLink>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(DocActions);
