import React, { Component } from 'react';
import utils from '../../helpers/utils';

class Li extends Component {
  render() {
    const { item, clickItem, delItem } = this.props;
    return (
      <li
        className="list-group-item p-2 d-flex"
        key={item.id}
        onClick={() => {
          clickItem(item);
        }}
      >
        <span>{utils.toJSON(item)}</span>
        {delItem && (
          <button
            className="ml-auto"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              delItem(item.id);
            }}
          >
            X
          </button>
        )}
      </li>
    );
  }
}

export default Li;
