import React, { Component } from 'react';
import Li from '../List/Li';

class Pagination extends Component {
  render() {
    const { nbPages, currentPage, onPageClick } = this.props;

    const clickHandler = page => {
      onPageClick(page);
    };

    const pageLinks = [];
    for (let i = 1; i <= nbPages; i++) {
      if (i === currentPage) {
        pageLinks.push(
          <li key={`page-${i}`} className="page-item">
            <a
              className="page-link active"
              href="#"
              onClick={() => {
                clickHandler(i);
              }}
            >
              {i}
            </a>
          </li>,
        );
      } else {
        pageLinks.push(
          <li key={`page-${i}`} className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={() => {
                clickHandler(i);
              }}
            >
              {i}
            </a>
          </li>,
        );
      }
    }

    return (
      <nav aria-label="Page navigation" className="p-2">
        <ul className="pagination justify-content-center">
          <li key="previous" className="page-item">
            <a
              className="page-link"
              href="#"
              aria-label="Previous"
              onClick={() => {
                if (currentPage > 1) {
                  clickHandler(currentPage - 1);
                }
              }}
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          {pageLinks}

          <li key="next" className="page-item">
            <a
              className="page-link"
              href="#"
              aria-label="Next"
              onClick={() => {
                if (currentPage < nbPages - 1) {
                  clickHandler(currentPage + 1);
                }
              }}
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
