import React, { Component } from 'react';
import { ScrollTop } from '../Control';

class ExportEditor extends Component {
  static defaultProps = {
    readOnly: true, //  'read-only' || 'editor'
    showActions: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      readOnly: props.readOnly,
    };
  }
  toggleMode() {
    this.setState({
      readOnly: !this.state.readOnly,
    });
    this.props.setMode(!this.state.readOnly);
  }
  cancel() {
    this.toggleMode();
    this.props.cancel();
  }
  save() {
    this.toggleMode();
    this.props.save();
  }
  render() {
    const { children, actions, showActions, header, readOnly } = this.props;

    return (
      <div className="editor">
        <ScrollTop />
        <div className="editor-header">
          {header}

          {showActions && (
            <div className="actions">
              {readOnly && (
                <>
                  <button
                    className="ml-auto btn btn-primary mr-1"
                    onClick={() => {
                      this.toggleMode();
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </button>
                  {actions}
                </>
              )}

              {!readOnly && (
                <>
                  <button
                    type="button"
                    className="btn btn-warning mr-1"
                    onClick={() => {
                      this.cancel();
                    }}
                  >
                    <i className="fas fa-undo"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-success mr-1"
                    onClick={() => {
                      this.save();
                    }}
                  >
                    <i className="far fa-save"></i>
                  </button>
                </>
              )}
            </div>
          )}
        </div>

        <div className="editor-children">{children}</div>
      </div>
    );
  }
}

export default ExportEditor;
