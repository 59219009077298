import utils from '../../helpers/utils';

const actions = [
  'FETCH_USERS',
  'FETCH_USERS_BEGIN',
  'FETCH_USERS_SUCCESS',
  'FETCH_USERS_ERROR',

  'FETCH_WORKOUTS',
  'FETCH_WORKOUTS_BEGIN',
  'FETCH_WORKOUTS_SUCCESS',
  'FETCH_WORKOUTS_ERROR',
];

export default utils.redux.createActionTypes('MONIT', actions);
