import React from 'react'; // React is present in scope in order to allow JSX usage
import _ from 'lodash';
import unorm from 'unorm';
import appConfig from '../appConfig';

const utils = {
  isEmpty: obj => {
    if (obj == 0) {
      return false;
    } else {
      return _.isEmpty(obj);
    }
  },
  isNum: obj => {
    return _.isNumber(obj);
  },
  arrayMove: (array, fromIndex, toIndex) => {
    var element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
  },
  getCurrentYear: () => {
    return new Date().getYear() + 1900;
  },
  formatNumber: num => {
    return ('0' + num).slice(-2);
  },
  formatBytes: (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },
  previousSunday: date => {
    let prevSun = new Date();
    prevSun.setDate(date.getDate() - date.getDay());

    return prevSun;
  },
  dateToString: data => {
    const date = new Date(data);
    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    return (
      date.getDate() +
      '/' +
      monthNames[date.getMonth()] +
      '/' +
      date.getFullYear()
    );
  },
  formatDate: data => {
    const date = new Date(data);
    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    return (
      <>
        {date.getDate()}/{monthNames[date.getMonth()]}/{date.getYear() + 1900}{' '}
        {utils.formatNumber(date.getHours())}:
        {utils.formatNumber(date.getMinutes())}
      </>
    );
  },
  formatEventDate: (data, isYearShown = true) => {
    if (!data) {
      return '';
    }

    const date = new Date(data);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return (
      <>
        {monthNames[date.getMonth()]} {date.getDate()}{' '}
        {isYearShown && (
          <strong className="date-year">{date.getFullYear()}</strong>
        )}
      </>
    );
  },
  // toDateTimeString: date => {
  //   const splittedDate = date.toISOString ? date.toISOString().split(/[T.]/) : [];
  //
  //   if (splittedDate) {
  //     return splittedDate[0] + ' ' + splittedDate[1];
  //   }
  //
  //   return ''
  // },
  toLocaleTimeString: date => {
    const splittedDate = date.toLocaleTimeString
      ? date.toLocaleTimeString().split(/:/)
      : [];

    if (splittedDate) {
      return splittedDate[0] + ':' + splittedDate[1];
    }

    return '';
  },
  getDateAtMidnightUTC: date => {
    if (!date) {
      return null;
    }

    const utcDate = new Date(date);
    if (utcDate.getUTCHours() !== 0) {
      utcDate.setUTCHours(24, 0, 0);
    }

    return utcDate;
  },
  normalizeString: str => {
    // check if str is String
    if (typeof str === 'string' || str instanceof String) {
      return unorm.nfd(str).replace(/[\u0300-\u036f]/g, '');
      // return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else {
      return null;
    }
  },
  toString: obj => <pre className="text-warning"> {obj.toString()}</pre>,
  toLowerCase: str => {
    if (str) {
      if (str.toLowerCase) {
        return str.toLowerCase();
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  toJSON: obj => (
    <>
      {Object.entries(obj).length > 0 && (
        <span className="text-warning">
          {obj && (
            <div>
              <span>{'{'}</span>
              <br />
              <pre className="ml-2 mb-0 text-warning">
                {JSON.stringify(obj)
                  .replace(/^\{/, '')
                  .replace(/\}$/, '')
                  .replace(/,/g, ',\n')
                  .replace(/\{/g, '{ \n')
                  .replace(/\}/g, '} \n')
                  .replace(/\[/g, '[ \n')
                  .replace(/\]/g, '] \n')}
              </pre>
              <span>{'}'}</span>
            </div>
          )}
          {!obj && 'undefined'}
        </span>
      )}
    </>
  ),
  toFormData: (obj, formData = new FormData(), namespace = '') => {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        let formKey = '';

        if (namespace) {
          formKey = `${namespace}[${property}]`;
        } else {
          formKey = property;
        }

        if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File) &&
          obj[property]
        ) {
          if (obj[property] instanceof Array) {
            if (obj[property].length === 0) {
              formData.append(formKey, '');
            }
          }

          // if the property is an objet but not a File, use recursivity
          utils.toFormData(obj[property], formData, formKey);
        } else {
          // if it's a strinf or File object, append it to the FormData
          const dataToAppend = obj[property] ? obj[property] : '';
          formData.append(formKey, dataToAppend);
        }
      }
    }

    return formData;
  },
  echoFormData: (obj, formData = new FormData(), namespace = '') => {
    const data = utils.toFormData(obj, formData, namespace);
    console.log('FORMDATA ------------------------------------------');
    for (var l of data.entries()) {
      console.log(l);
    }
  },

  isToday: someDate => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  },

  isKeyIn: (key, array) => {
    return Array.isArray(array) ? array.indexOf(key) : key in array;
  },

  isColPresent: (label, array) => {
    return array.filter(el => el.label === label).shift() ? true : false;
  },

  hasObjectMatch: (objKey, value, array) => {
    return array.filter(el => utils.getObjKey(el, objKey) === value).shift()
      ? true
      : false;
  },

  getObjKeySerialized: (objData, key) => {
    let data = utils.getObjKey(objData, key);
    if (data instanceof Array) {
      data = data[0];
    }
    if (data && typeof data === 'object') {
      data = data.code || data.name;
    }
    return data;
  },

  getObjKey: (objData, key) => {
    let dataToUpd = objData;
    const deep = key.split('.');
    for (var i = 0; i < deep.length - 1; i++) {
      dataToUpd = dataToUpd[deep[i]];
    }
    return dataToUpd[deep[i]];
  },

  updateObjKey: (objData, key, value) => {
    let dataToUpd = objData;
    const deep = key.split('.');
    for (var i = 0; i < deep.length - 1; i++) {
      dataToUpd = dataToUpd[deep[i]];
    }
    // console.log(dataToUpd, 'dataToUpd');
    dataToUpd[deep[i]] = value;
    return objData;
  },

  filterByKey: (itemsToShow, filterData, itemKey, filterKey) => {
    if (filterData) {
      itemsToShow = itemsToShow.filter(item => {
        let found = false;
        filterData.forEach((itemFilterData, i) => {
          if (
            utils.getObjKey(item, itemKey) ===
            utils.getObjKey(itemFilterData, filterKey)
          ) {
            found = true;
            return;
          }
        });
        return found;
      });
      return itemsToShow;
    } else {
      return itemsToShow;
    }
  },
  filterByKeyForMultiValueField: (
    itemsToShow,
    filterData,
    itemKey,
    elementKey,
    filterKey,
  ) => {
    if (filterData) {
      itemsToShow = itemsToShow.filter(item => {
        let found = false;
        filterData.forEach((itemFilterData, i) => {
          utils.getObjKey(item, itemKey).forEach((element, i) => {
            if (
              utils.getObjKey(element, elementKey) ===
              utils.getObjKey(itemFilterData, filterKey)
            ) {
              found = true;
              return;
            }
          });
        });
        return found;
      });
      return itemsToShow;
    } else {
      return itemsToShow;
    }
  },
  sortByKey: (
    filteredItems,
    sortByKey,
    ascOrder,
    isStringNormalized = false,
  ) => {
    if (sortByKey) {
      if (isStringNormalized) {
        return _.clone(filteredItems).sort((a, b) => {
          let strA = utils.getObjKeySerialized(a, sortByKey);
          let strB = utils.getObjKeySerialized(b, sortByKey);

          // remove letter with accents
          strA = utils.normalizeString(strA);
          strB = utils.normalizeString(strB);

          if (!strA) {
            return ascOrder ? 1 : -1;
          }
          if (!strB) {
            return ascOrder ? -1 : 1;
          }
          if (strA < strB) {
            return ascOrder ? -1 : 1;
          }
          if (strA > strB) {
            return ascOrder ? 1 : -1;
          }
          if (strA === strB) {
            return 0;
          }
          return 0;
        });
      } else {
        const sortKeysWithCode = ['gender', 'type', 'classification', 'class'];
        if (sortKeysWithCode.indexOf(sortByKey) > -1) {
          sortByKey += '.code';
        }

        return _.orderBy(filteredItems, sortByKey, ascOrder ? 'asc' : 'desc');
      }
    }

    return _.clone(filteredItems);
  },

  itemLookup: (items, searchInput) => {
    const recursiveMode = true;

    var iterateProp = (item, searchInputRegex) => {
      let match = false;
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          // console.log(`${key} : ${item[key]}`)
          if (typeof item[key] !== 'object') {
            if (item[key].toString().toLowerCase().match(searchInputRegex)) {
              match = true;
              break;
            }
          } else if (recursiveMode) {
            match = iterateProp(item[key], searchInputRegex);
            if (match) {
              break;
            }
          }
        }
      }
      return match;
    };

    let filteredItems = items;
    if (searchInput && searchInput !== '') {
      const searchInputRegex = new RegExp(searchInput.toLowerCase(), 'g');
      filteredItems = items.filter(item => {
        return iterateProp(item, searchInputRegex);
      });
    }

    return filteredItems;
  },
  parseJwt: token => {
    var base64Url = token.split('.')[1];
    if (base64Url) {
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } else {
      return {};
    }
  },
  getCurrentToken: () => {
    const TOKEN_KEY = appConfig.settings.tokenKey || 'app-key:api_token';
    const token = sessionStorage.getItem(TOKEN_KEY);
    return token;
  },
  redux: {
    createActionTypes: (actionPrefix, actions) => {
      let exportedActions = {};
      actions.map(
        action => (exportedActions[action] = actionPrefix + '_' + action),
      );
      return exportedActions;
    },
    collectionDeleteId: (collectionState, id) => {
      const posOfId = collectionState.findIndex(e => {
        return e._id === id;
      });

      collectionState = [
        ...collectionState.slice(0, posOfId),
        ...collectionState.slice(posOfId + 1),
      ];

      return collectionState;
    },
    collectionUpdateItem: (collectionState, item) => {
      let action = 'update';
      const posOfIdToUpd = collectionState.findIndex(e => {
        return e._id === item._id;
      });
      collectionState[posOfIdToUpd] = item;

      // append to the end if not found
      if (posOfIdToUpd < 0) {
        // collectionState.push(item);
        action = 'add';
        collectionState.unshift(item);
      }

      return { collection: collectionState, action: action };
    },
    collectionUpdate: (collectionState, newItems, startPosition) => {
      newItems.forEach(newItem => {
        const foundIndex = collectionState.findIndex(
          item => item && item._id === newItem._id,
        );
        if (foundIndex >= 0) {
          collectionState[foundIndex] = newItem;
        } else {
          collectionState[startPosition] = newItem;
          startPosition++;
        }
      });

      // replace null item by empty object
      const lastPosition = startPosition;
      for (let i = 0; i < lastPosition; i++) {
        if (!collectionState[i]) {
          collectionState[i] = {};
        }
      }

      return collectionState;
    },
  },

  // toQueryParam: obj => {
  //   return Object.keys(obj).map((key) => {
  //     return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  //   }).join('&');
  // },
};

export default utils;
