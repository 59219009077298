import React, { useEffect, useState } from 'react';
import {
  FileLibraryListItem,
  ReactMediaLibrary,
  FileMeta,
} from 'react-media-library';
import MediaLibraryItem from './MediaLibraryItem';
import appConfig from '../../appConfig';
import axios from '../../axiosConfig';

const API_URL = appConfig.api_url;
const ENTITY_URL = 'media';
const ENTITY = 'Media';
const getMedias = async (start, limit, textSearch, jsonSearch) => {
  let queryParams = [];
  if (start > 0) {
    queryParams.push(`start=${start}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (!_.isEmpty(textSearch)) {
    queryParams.push(`textSearch=${textSearch}`);
  }
  if (!_.isEmpty(jsonSearch)) {
    queryParams.push(`search=${jsonSearch}`);
  }
  const response = await axios.get(
    `${API_URL}/${ENTITY_URL}?${queryParams.join('&')}`,
  );

  let items = response.data.data;
  let count = response.data.count;

  return items;
};

const fileUpload = async (fileBase64, fileMeta) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${API_URL}/${ENTITY_URL}/upload`,
      data: {
        file: fileBase64,
        fileMeta,
      },
    });

    if (response.data) {
      return {
        success: true,
        items: response.data,
      };
    }
  } catch (error) {
    console.error(error);
  }

  return {
    success: false,
    items: [],
  };
};

const fileDelete = async item => {
  console.log(item);
  try {
    const response = await axios({
      method: 'delete',
      url: `${API_URL}/${ENTITY_URL}/${item._id}`,
    });

    if (response) {
      console.log(response);
      return {
        success: true,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
    };
  }
};

const imageJsonSearch = JSON.stringify({
  $or: [
    { type: 'image/png' },
    { type: 'image/jpg' },
    { type: 'image/jpeg' },
    { type: 'image/gif' },
  ],
});

console.log(imageJsonSearch);

const videoJsonSearch = JSON.stringify({
  $or: [{ type: 'video/mp4' }, { type: 'video/ogg' }, { type: 'video/webm' }],
});

const MediaLibrary = ({ onSelect, type, show }) => {
  const [display, setDisplay] = useState(show || false);
  const [fileLibraryList, setFileLibraryList] = useState([]);

  useEffect(async () => {
    // TODO Get file list from database
    // const medias =
    //   type === 'video'
    //     ? await getMedias(null, 1000, null, videoJsonSearch)
    //     : await getMedias(null, 1000, null, imageJsonSearch);
    let medias;
    switch (type) {
      case 'video':
        medias = await getMedias(null, 1000, null, videoJsonSearch);
        break;

      case 'image':
        medias = await getMedias(null, 1000, null, imageJsonSearch);
        break;

      default:
        medias = await getMedias(null, 1000, null, null);
        break;
    }
    setFileLibraryList(medias);
  }, [display]);

  async function uploadCallback(fileBase64, fileMeta) {
    // TODO Upload file to backend APIs
    const result = await fileUpload(fileBase64, fileMeta);

    if (result.success) {
      // New file uploaded
      // TODO Reacquire new file list from database
      // const newFileList = await getNewFileList();
      const newFileList =
        type === 'video'
          ? await getMedias(null, 1000, null, videoJsonSearch)
          : await getMedias(null, 1000, null, imageJsonSearch);
      setFileLibraryList(newFileList);

      // Return true to display upload success in modal
      return true;
    } else {
      // Return false to display upload failed in modal
      return false;
    }
  }

  function selectCallback(item) {
    // Hide modal
    setDisplay(false);

    // TODO Pass selected file back to client component callback function
    onSelect(item);
  }

  async function deleteCallback(item) {
    // TODO Delete file from backend service
    const result = await fileDelete(item);

    if (result.success) {
      // Deleted file
      // TODO Reacquire new file list from database
      // const newFileList = await getNewFileList();
      const newFileList = await getMedias();
      setFileLibraryList(newFileList);
    }
  }

  return (
    <>
      <div>
        <span className="btn btn-light" onClick={() => setDisplay(true)}>
          <i className="fas fa-photo-video"></i>
        </span>
      </div>
      <ReactMediaLibrary
        show={display}
        onHide={() => setDisplay(false)}
        fileUploadCallback={uploadCallback}
        fileLibraryList={fileLibraryList}
        fileSelectCallback={selectCallback}
        fileDeleteCallback={deleteCallback}
        libraryCardComponent={MediaLibraryItem}
      />
    </>
  );
};

export default MediaLibrary;
