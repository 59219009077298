import React, { Component } from 'react';
import 'loaders.css';

const classNames = {
  1000: ' alert-info',
  200: ' alert-success',
  500: ' alert-danger',
};

class Alert extends Component {
  static defaultProps = {};
  render() {
    const { message, statusCode } = this.props;

    const className = classNames[statusCode.toString()];

    return (
      <div className="notification-wrapper">
        <div className={'notification shadow alert' + className}>{message}</div>
      </div>
    );
  }
}

export default Alert;
