import React, { Component } from 'react';
import Select from 'react-select';
import { Modal } from '../../components/Button';
import { Input, Checkbox } from '../../components/Input';
import { Row, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

class OptionManager extends Component {
  constructor(props) {
    super(props);

    const visibility = props.selected
      ? props.selected.default_visibility
      : false;

    this.state = {
      isConfirmButtonShown: false,
      selected: props.selected,
      toAdd: null,
      visibility: visibility,
      forceCloseModal: false,
      isSelectedOverriden: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('OptionManager.componentDidUpdate');
    const { selected } = this.props;
    const { forceCloseModal } = this.state;

    if (selected && !prevProps.selected) {
      if (selected.value) {
        this.setState({
          selected: selected.value,
        });
        return;
      }
    }

    if (this.state.selected) {
      const visibility_support =
        this.state.selected.hasOwnProperty('default_visibility');
      if (visibility_support) {
        if (!_.isEqual(this.state.selected, prevState.selected)) {
          this.setState({
            visibility: this.state.selected.default_visibility,
          });
        }
      }
    }

    if (forceCloseModal) {
      this.setState({
        forceCloseModal: false,
      });
    }
  }
  updateData(o) {
    const data = o ? o.value : null;
    this.setState({
      selected: data,
      isSelectedOverriden: true,
    });
  }
  setOptionToAdd(text) {
    // const data = { code : text };
    this.setState({
      toAdd: text,
    });
  }
  setOptionVisibility() {
    this.setState({
      visibility: !this.state.visibility,
    });
  }
  toggleConfirmButton() {
    const { isConfirmButtonShown } = this.state;
    this.setState({
      isConfirmButtonShown: !isConfirmButtonShown,
    });
  }
  closeOptionManagerModal() {
    this.setState({
      toAdd: null,
      forceCloseModal: true,
    });
  }
  render() {
    const {
      disabled,
      optionManagerTitle,
      parentOption,
      addOption,
      updOption,
      delOption,
    } = this.props;
    const {
      selected,
      toAdd,
      visibility,
      isConfirmButtonShown,
      forceCloseModal,
    } = this.state;
    let { options } = this.props;
    options = options || [];

    let selectedOption = null;
    if (selected) {
      const selectedFound = options.filter(o => o.id === selected.id);

      if (selectedFound.length > 0) {
        selectedOption = {
          label: selected.code || selected.name,
          value: selected,
        };
      }
    }

    const optionList = options.map(option => {
      option.code = option.code || option.name;
      return {
        label: option.code,
        value: option,
      };
    });

    const visibility_support =
      options && options[0]
        ? options[0].hasOwnProperty('default_visibility')
        : false;
    let optionDataToAdd, optionDataToUpd, optionDataToDel;
    if (isConfirmButtonShown) {
      optionDataToAdd = visibility_support
        ? { code: toAdd, visibility: visibility }
        : { code: toAdd };
      if (selectedOption) {
        optionDataToUpd = visibility_support
          ? { code: toAdd, id: selectedOption.value.id, visibility: visibility }
          : { code: toAdd, id: selectedOption.value.id };
        optionDataToDel = visibility_support
          ? { id: selectedOption.value.id }
          : { id: selectedOption.value.id };
      }
    }

    return (
      <Modal
        forceClose={forceCloseModal}
        disabled={disabled}
        size="md"
        buttonText={<i className="fas fa-pen"></i>}
        title={optionManagerTitle}
        className="btn btn-light"
      >
        {parentOption && (
          <div className="parent-option">
            {parentOption.code || parentOption.name}
          </div>
        )}

        <Tabs defaultActiveKey="create" id="uncontrolled-tab-example">
          {/*ADD*/}
          <Tab eventKey="create" title="Create new item">
            <Row>
              <div className="col">
                <div className="form-group m-2">
                  <Input
                    type="text"
                    name="code"
                    value={toAdd}
                    updateEditorData={value => {
                      this.setOptionToAdd(value);
                    }}
                  />
                </div>

                {visibility_support && (
                  <div className="form-group m-2">
                    <Checkbox
                      disabled={false}
                      value={visibility}
                      label="Visibility"
                      updateEditorData={() => {
                        this.setOptionVisibility();
                      }}
                    />
                  </div>
                )}

                <div className="form-group m-2 text-center">
                  {isConfirmButtonShown && (
                    <>
                      <span
                        className="btn btn-secondary ml-1"
                        onClick={() => {
                          addOption(optionDataToAdd);
                          this.closeOptionManagerModal();
                        }}
                      >
                        Confirm
                      </span>
                      <span
                        className="btn btn-danger ml-1"
                        onClick={() => {
                          this.toggleConfirmButton();
                        }}
                      >
                        Cancel
                      </span>
                    </>
                  )}

                  {!isConfirmButtonShown && (
                    <span
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        this.toggleConfirmButton();
                      }}
                    >
                      Add option
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Tab>

          {/*UPDATE*/}
          <Tab eventKey="update" title="Update">
            <Row>
              <div className="col">
                <div className="form-group m-2">
                  <Select
                    value={selectedOption}
                    onChange={o => {
                      this.updateData(o);
                    }}
                    options={optionList}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={disabled}
                    classNamePrefix="select"
                  />
                </div>
                <div className="form-group m-2">
                  <Input
                    type="text"
                    name="code"
                    value={toAdd}
                    updateEditorData={value => {
                      this.setOptionToAdd(value);
                    }}
                  />
                </div>

                {visibility_support && (
                  <div className="form-group m-2">
                    <Checkbox
                      disabled={false}
                      value={visibility}
                      label="Visibility"
                      updateEditorData={() => {
                        this.setOptionVisibility();
                      }}
                    />
                  </div>
                )}

                <div className="form-group m-2 text-center">
                  {isConfirmButtonShown && (
                    <>
                      <span
                        className="btn btn-secondary ml-1"
                        onClick={() => {
                          updOption(optionDataToUpd);
                          this.closeOptionManagerModal();
                        }}
                      >
                        Confirm
                      </span>
                      <span
                        className="btn btn-danger ml-1"
                        onClick={() => {
                          this.toggleConfirmButton();
                        }}
                      >
                        Cancel
                      </span>
                    </>
                  )}

                  {!isConfirmButtonShown && (
                    <span
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        this.toggleConfirmButton();
                      }}
                    >
                      Update option
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Tab>

          {/*DELETE*/}
          <Tab eventKey="delete" title="Delete">
            <Row>
              <div className="col">
                <div className="form-group m-2">
                  <Select
                    value={selectedOption}
                    onChange={o => {
                      this.updateData(o);
                    }}
                    options={optionList}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={disabled}
                    classNamePrefix="select"
                  />
                </div>
                <div className="form-group m-2 text-center">
                  {isConfirmButtonShown && (
                    <>
                      <span
                        className="btn btn-secondary ml-1"
                        onClick={() => {
                          delOption(optionDataToDel);
                          this.closeOptionManagerModal();
                        }}
                      >
                        Confirm
                      </span>
                      <span
                        className="btn btn-danger ml-1"
                        onClick={() => {
                          this.toggleConfirmButton();
                        }}
                      >
                        Cancel
                      </span>
                    </>
                  )}

                  {!isConfirmButtonShown && (
                    <span
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        this.toggleConfirmButton();
                      }}
                    >
                      Delete option
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Tab>
        </Tabs>
      </Modal>
    );
  }
}

export default OptionManager;
