import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppRoutes from './AppRoutes';
import ProtectedContent from '../components/ProtectedContent';
import Login from '../pages/Login';
import appConfig from '../appConfig';
import Feedback from 'feeder-react-feedback';
import 'feeder-react-feedback/dist/feeder-react-feedback.css';

import * as Actions from '../redux/app/actions';

import 'react-datepicker/dist/react-datepicker.css';
import '../assets/sass/app.scss';

const TOKEN_KEY = appConfig.settings.tokenKey || 'app-key:api_token';
const USER_KEY = appConfig.settings.userKey || 'user-key:api_token';

const mapStateToProps = state => {
  return {
    loggedInUser: state.app.loggedInUser,
    token: state.app.token,
    settings: state.app.settings,
    actionMessage: state.app.actionMessage,
    isSidebarConfirmationEnabled: state.app.isSidebarConfirmationEnabled,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(Actions.login(email, password)),
    loginWithToken: token => dispatch(Actions.loginWithToken(token)),
    logout: () => dispatch(Actions.logout()),
    updSettings: (user, settings) =>
      dispatch(Actions.updSettings(user, settings)),

    clearActionMessage: () => dispatch(Actions.messageClear()),
  };
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: false,
      offcanvas: props.settings.offcanvas || false,
    };

    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      this.state.isLoggedin = true;
      this.props.loginWithToken(token);
    }
  }
  componentDidUpdate(prevProps) {
    const { token, loggedInUser } = this.props;
    if (token !== prevProps.token) {
      sessionStorage.setItem(TOKEN_KEY, token);
      sessionStorage.setItem(USER_KEY, JSON.stringify(loggedInUser));
    }
  }
  login(email, password) {
    // this.props.login(email, password);
    // this.props.login('full-control@example.com', 'password');
    console.log('login');
    this.props.clearActionMessage();
    this.props.login(email, password);

    // this.setState({
    //   isLoggedin: true
    // });
  }
  logout() {
    this.props.logout();
    // this.props.clearEventsCollection();
    this.props.clearActionMessage();

    sessionStorage.setItem(TOKEN_KEY, null);
    sessionStorage.setItem(USER_KEY, null);
  }
  toggleOffcanvas() {
    const { loggedInUser, settings } = this.props;
    const { offcanvas } = settings;

    // this.setState({
    //   offcanvas: updOffcanvas
    // })

    this.props.updSettings(
      loggedInUser,
      Object.assign({}, settings, { offcanvas: !offcanvas }),
    );
  }
  render() {
    const { loggedInUser, actionMessage, isSidebarConfirmationEnabled } =
      this.props;
    const { offcanvas } = this.props.settings;

    let isLoggedin = false;
    if (loggedInUser) {
      isLoggedin = true;
      // sessionStorage.setItem(TOKEN_KEY, token);
      // sessionStorage.setItem(USER_KEY, JSON.stringify(loggedInUser));
    }

    const offcanvasClassName = offcanvas ? 'offcanvas' : '';
    const toggleActiveClassName = offcanvas ? 'active' : '';

    const NavToggle = () => (
      <button
        className={'nav-toggle ' + toggleActiveClassName}
        onClick={() => {
          this.toggleOffcanvas();
        }}
      >
        <i></i>
      </button>
    );

    return (
      <Router>
        <div className={'app ' + offcanvasClassName}>
          <ProtectedContent
            isProtected={!isLoggedin}
            contentFallback={
              <Login
                actionMessage={actionMessage}
                login={(email, password) => {
                  this.login(email, password);
                }}
              />
            }
          >
            <AppSidebar
              loggedInUser={loggedInUser}
              logout={() => {
                this.logout();
              }}
              isSidebarConfirmationEnabled={isSidebarConfirmationEnabled}
            />
            <div className="app-content">
              <AppHeader
                loggedInUser={loggedInUser}
                logout={() => {
                  this.logout();
                }}
                toggleButton={<NavToggle></NavToggle>}
                isSidebarConfirmationEnabled={isSidebarConfirmationEnabled}
              />
              <AppRoutes loggedInUser={loggedInUser} />
            </div>
            <Feedback
              projectId="632dae1944e87300045f0cc8"
              email
              emailRequired
            />
          </ProtectedContent>
        </div>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
