import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import UserStatResume from '../containers/user/UserStatResume';
import DocStatResume from '../containers/doc/DocStatResume';
import EquipementStatResume from '../containers/equipment/EquipementStatResume';
import ExerciseStatResume from '../containers/exercise/ExerciseStatResume';
import ProgrammeStatResume from '../containers/programme/ProgrammeStatResume';
import RecoveryProgrammeStatResume from '../containers/recovery/RecoveryProgrammeStatResume';
import { ScrollTop } from '../components/Control';
import appConfig from '../appConfig';

// Detail
import Single from '../containers/user/Single';

// Table
import { TableRouter } from '../components/Table';
import LastUpdatedUserTable from '../containers/user/LastUpdatedUserTable';

const clickItem = (item, history) => {
  history.push('/users/' + item._id);
};

export default withRouter(props => {
  return (
    <PageLayout className="dashboard">
      <ScrollTop />

      <div className="page-header">
        <div className="page-title">
          <h1>Dashboard</h1>
        </div>
      </div>

      <div className="mb-1">
        <strong>Version :</strong> <span>{appConfig.version}</span>
      </div>

      <div className="dashboard-widgets">
        <Row>
          <UserStatResume />
          <DocStatResume />
          <EquipementStatResume />
          <ExerciseStatResume />
          <ProgrammeStatResume />
          <RecoveryProgrammeStatResume />
        </Row>
      </div>

      <TableRouter
        clickItem={item => {
          clickItem(item, props.history);
        }}
        rootPath=""
        tableComponent={LastUpdatedUserTable}
        detailViewComponent={Single}
      ></TableRouter>

      {/* <LastLogTable /> */}
    </PageLayout>
  );
});
