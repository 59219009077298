import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import utils from '../../helpers/utils';

class TableRouter extends Component {
  static defaultProps = {
    rootPath: '',
    attrForSlug: '_id',
    detailViewComponent: () => 'please pass an detailViewComponent prop',
    tableComponent: () => 'please pass a tableComponent prop',
  };
  clickItem(item) {
    const { history, clickItem, attrForSlug } = this.props;
    if (!clickItem) {
      history.push(
        this.componentUrl() + '/' + utils.getObjKey(item, attrForSlug),
      );
    } else {
      clickItem(item);
    }
  }
  componentUrl() {
    const { history } = this.props;
    return history.location.pathname;
  }
  componentPath() {
    const { rootPath, match } = this.props;
    return match.path + rootPath;
  }
  render() {
    const { detailViewComponent, tableComponent, attrForSlug } = this.props;

    // Rename to Capitalize in order to tell React
    //  to render as a Dynamic component
    const ItemComponentCr = detailViewComponent;
    const TableComponentCr = tableComponent;

    return (
      <Switch>
        <Route
          path={this.componentPath() + '/edit' + '/:' + attrForSlug}
          render={props => <ItemComponentCr {...props} readOnlyMode={false} />}
        />
        <Route
          path={this.componentPath() + '/duplicate' + '/:' + attrForSlug}
          render={props => (
            <ItemComponentCr {...props} readOnlyMode={false} isDuplicata />
          )}
        />
        <Route
          path={this.componentPath() + '/delete' + '/:' + attrForSlug}
          render={props => (
            <ItemComponentCr {...props} readOnlyMode={true} deleteMode={true} />
          )}
        />
        <Route
          path={this.componentPath() + '/:' + attrForSlug}
          render={props => <ItemComponentCr {...props} />}
        />
        <Route
          path={this.componentPath()}
          render={props => (
            <TableComponentCr
              {...props}
              clickItem={item => {
                this.clickItem(item);
              }}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(TableRouter);
