import React, { Component } from 'react';
import utils from '../../helpers/utils';

class MultiTag extends Component {
  static defaultProps = {
    showAdder: true,
  };
  render() {
    let { selected } = this.props;
    selected = selected || [];

    const selectedList = selected.map(option => {
      if (utils.toLowerCase(option) === utils.toLowerCase(selected)) {
        return (
          <span
            key={'option-' + option.id}
            className={'mr-1 badge ' + option.status}
          >
            {option.code}
          </span>
        );
      } else {
        return null;
      }
    });

    return (
      <>
        <div className={'multi-badge'}>{selectedList}</div>
      </>
    );
  }
}

export default MultiTag;
