import React, { Component } from 'react';
import FileUploader from './FileUploader';

class ImageUploader extends Component {
  // static defaultProps = {}
  constructor(props) {
    super(props);
    this.state = {
      isImagePreviewShown: props.imageUrl ? true : false,
    };
  }
  showPreview() {
    this.setState({
      isImagePreviewShown: true,
    });
  }
  hidePreview() {
    this.setState({
      isImagePreviewShown: false,
    });
  }
  updateData(data) {
    const { updateEditorData } = this.props;

    this.showPreview();

    updateEditorData && updateEditorData(data);
  }
  componentDidUpdate(prevProps) {
    if (
      !prevProps.imageUrl &&
      this.props.imageUrl &&
      !this.state.isImagePreviewShown
    ) {
      this.showPreview();
    }
  }
  render() {
    const { disabled, imageUrl, deleteEditorData } = this.props;
    const { isImagePreviewShown } = this.state;

    // console.log(imageUrl, 'imageUrl');
    // console.log(isImagePreviewShown, 'isImagePreviewShown');

    return (
      <>
        <div className="logo-upload card">
          {(!imageUrl || imageUrl === '') && disabled && (
            <div className="text-center">No logo</div>
          )}

          {imageUrl && imageUrl !== '' && isImagePreviewShown && (
            <img
              className="card-img-top"
              src={imageUrl}
              alt="Event name logo"
            />
          )}

          {!disabled && !isImagePreviewShown && (
            <FileUploader
              selector=".logo-upload img"
              updateEditorData={data => {
                this.updateData(data[0]);
              }}
            />
          )}

          <div className="card-body">
            {!disabled && imageUrl && (
              <span
                href="#"
                className="card-link"
                onClick={() => {
                  deleteEditorData();
                }}
              >
                Remove
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ImageUploader;
