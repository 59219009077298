import React, { Component } from 'react';
import 'loaders.css';

const classNames = {
  200: ' alert-success',
  500: ' alert-danger',
};

class Switcher extends Component {
  static defaultProps = {
    langs: [],
  };
  render() {
    const { langs, switchLang, activeLang } = this.props;

    const activeLangClassname = l => {
      if (l === activeLang) {
        return ' active';
      } else {
        return '';
      }
    };

    return (
      <div className="d-flex">
        <ul className="nav nav-pills ml-auto mb-1">
          {langs.map((lang, i) => (
            <li key={i} className="nav-item">
              <a
                className={'nav-link' + activeLangClassname(lang)}
                onClick={() => {
                  switchLang(lang);
                }}
              >
                {lang.toUpperCase()}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Switcher;
