import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

class DropdownPicker extends Component {
  static defaultProps = {};
  render() {
    const { size, variant, items, selected } = this.props;

    const yearList = items.map(year => (
      <Dropdown.Item key={'year-' + year} as={Link} to={'/events/' + year}>
        {year}
      </Dropdown.Item>
    ));

    return (
      <Dropdown>
        <Dropdown.Toggle variant={variant} size={size} id="dropdown-basic">
          {selected}
        </Dropdown.Toggle>

        <Dropdown.Menu>{yearList}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withRouter(DropdownPicker);
