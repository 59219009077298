import React, { Component } from 'react';
import Select from 'react-select';
import { Modal } from '../../components/Button';
import { Input } from '../../components/Input';
import { Row, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

class SelectObj extends Component {
  // constructor(props){
  //   super(props);
  // }
  updateData(data) {
    const { updateEditorData } = this.props;

    const selectedOption = data ? data.map(option => option.value) : null;
    updateEditorData && updateEditorData(selectedOption);
  }
  render() {
    const { disabled, crudManagerTitle } = this.props;
    let { selected, options, error } = this.props;
    selected = selected || [];
    options = options || [];

    let selectedOption;
    let optionList;

    if (_.isObject(options[0])) {
      selectedOption = selected.map(option => {
        let found = options.find(o => o.value == option);
        if (found) {
          return {
            label: found.label,
            value: found.value,
          };
        }
      });

      optionList = options.map(option => {
        return {
          label: option.label,
          value: option.value,
        };
      });
    } else {
      selectedOption = selected.map(option => ({
        label: option,
        value: option,
      }));

      optionList = options.map(option => {
        return {
          label: option,
          value: option,
        };
      });
    }

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto" style={{ width: '100%' }}>
            <Select
              value={selectedOption}
              onChange={o => {
                this.updateData(o);
              }}
              options={optionList}
              isSearchable={true}
              isClearable={true}
              isDisabled={disabled}
              classNamePrefix="select"
              isMulti={true}
            />
          </div>

          {!disabled && crudManagerTitle && (
            <div className="ml-1">
              <Modal
                disabled={disabled}
                size="md"
                buttonText={<i className="fas fa-pen"></i>}
                title={crudManagerTitle}
                className="btn btn-light"
              >
                <Tabs defaultActiveKey="create" id="uncontrolled-tab-example">
                  <Tab eventKey="create" title="Create new item">
                    <Row>
                      <div className="col">
                        <div className="form-group m-2">
                          <Input type="text" name="diffusion" />
                        </div>
                      </div>
                    </Row>
                  </Tab>
                  <Tab eventKey="update" title="Update"></Tab>
                  <Tab eventKey="delete" title="Delete"></Tab>
                </Tabs>
              </Modal>
            </div>
          )}
        </div>
        {error && (
          <>
            {error.map(e => (
              <div className="pt-1">
                <span className="text-danger">{e}</span>
                <br />
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}

export default SelectObj;
