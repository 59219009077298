import React, { Component } from 'react';
import Select from './Select';
import appConfig from '../../appConfig';

class SelectObj extends Component {
  // static defaultProps = {}
  constructor(props) {
    super(props);
  }

  updateData(o) {
    const { selected, disabled, error, updateEditorData } = this.props;
    updateEditorData && updateEditorData(o.value || o);
  }

  render() {
    const { selected, options, disabled, error, updateEditorData } = this.props;

    return (
      <div className="d-flex">
        <div
          className="ml-auto mb-3 border border-light"
          style={{ width: '140px' }}
        >
          <Select
            disabled={disabled}
            selected={selected}
            options={options || appConfig.status}
            updateEditorData={data => {
              this.updateData(data);
            }}
            error={error}
          />
        </div>
      </div>
    );
  }
}

export default SelectObj;
