import React, { Component } from 'react';
import logo from '../../assets/imgs/default-avatar.svg';

class Avatar extends Component {
  static defaultProps = {
    size: 'std',
  };
  render() {
    const { avatar, size, className } = this.props;

    let classNames = size === 'sm' ? 'avatar-sm' : '';
    classNames = classNames + ' ' + className;

    return (
      <div className={'avatar ' + classNames}>
        <img src={logo} alt="avatar" className="rounded-circle" />
      </div>
    );
  }
}

export default Avatar;
