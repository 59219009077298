import React, { Component } from 'react';
import MediaLibrary from '../Media/MediaLibrary';

const imgStyle = {
  width: '220px',
  height: 'auto',
  backgroundColor: '#fff',
};

class Media extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
  };
  updateData(value) {
    const { updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  render() {
    const { disabled, name, type, className, error } = this.props;
    let { value } = this.props;
    value = value || '';

    return (
      <>
        <div className="d-flex">
          {/* <input
            disabled={disabled}
            type={type}
            className={'mr-1 form-control ' + className}
            name={name}
            value={typeof value === 'object' ? value.name : value}
            onChange={e => {
              this.updateData(e.target.value);
            }}
          /> */}

          {!value && <div className="p-1">No {type} selected yet</div>}

          {value && (
            <>
              {value.type.match(/image/) && (
                <img variant="top" src={value.thumbnailUrl} style={imgStyle} />
              )}

              {value.type.match(/video/) && (
                <video style={imgStyle} key={value.thumbnailUrl}>
                  <source src={value.thumbnailUrl} type={value.type} />
                  Your browser does not support the video tag.
                </video>
              )}
            </>
          )}

          {!disabled && (
            <>
              <div>
                <span
                  className="btn btn-light ml-1 mr-1"
                  onClick={() => this.updateData(null)}
                >
                  <i className="fas fa-trash-alt"></i>
                </span>
              </div>
              <MediaLibrary
                type={type}
                onSelect={o => {
                  this.updateData(o);
                }}
              />
            </>
          )}
        </div>
        {error && (
          <>
            {error.map(e => (
              <div className="pt-1">
                <span className="text-danger">{e}</span>
                <br />
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}

export default Media;
