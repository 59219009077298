import React, { Component } from 'react';

class Input extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
    rows: 1,
  };
  updateData(value) {
    const { updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  render() {
    const { disabled, name, type, className, rows, error } = this.props;
    let { value } = this.props;
    value = value || '';

    return (
      <>
        <textarea
          disabled={disabled}
          rows={rows}
          type={type}
          className={'form-control ' + className}
          name={name}
          value={value}
          onChange={e => {
            this.updateData(e.target.value);
          }}
        />
        {error && (
          <>
            {error.map(e => (
              <div className="pt-1">
                <span className="text-danger">{e}</span>
                <br />
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}

export default Input;
