import React, { Component } from 'react';
import Select from 'react-select';
import { Modal } from '../../components/Button';
import { Input } from '../../components/Input';
import { Row, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

class OptionManagerWithParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmButtonShown: false,
      selected: props.selected,
      selectedParent: props.selectedParent,
      toAdd: null,
      forceCloseModal: false,
      isSelectedOverriden: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { selected, selectedParent } = this.props;
    const { forceCloseModal } = this.state;

    if (selected && !prevProps.selected) {
      if (selected.value) {
        this.setState({
          selected: selected.value,
        });
        return;
      }
    }

    if (!_.isEqual(selectedParent, prevProps.selectedParent)) {
      if (selectedParent) {
        this.setState({
          selectedParent: selectedParent.value,
        });
        return;
      }
    }

    if (forceCloseModal) {
      this.setState({
        forceCloseModal: false,
      });
    }
  }
  updateData(o) {
    // const { updateEditorData } = this.props;
    //
    // const data = o ? o.value : null;
    // updateEditorData && updateEditorData(data);

    const data = o ? o.value : null;
    this.setState({
      selected: data,
      isSelectedOverriden: true,
    });
  }
  updateParent(o) {
    // const { updateEditorData } = this.props;
    //
    // const data = o ? o.value : null;
    // updateEditorData && updateEditorData(data);

    const data = o ? o.value : null;
    this.setState({
      selectedParent: data,
      isSelectedOverriden: true,
    });
  }
  setOptionToAdd(text) {
    console.log('setOptionToAdd');
    // const data = { code : text };
    this.setState({
      toAdd: text,
    });
  }
  toggleConfirmButton() {
    const { isConfirmButtonShown } = this.state;
    this.setState({
      isConfirmButtonShown: !isConfirmButtonShown,
    });
  }
  closeOptionManagerModal() {
    // $('body').trigger('click');
    this.setState({
      toAdd: null,
      forceCloseModal: true,
    });
  }
  render() {
    const { disabled, optionManagerTitle, addOption, updOption, delOption } =
      this.props;
    const {
      selected,
      selectedParent,
      toAdd,
      isConfirmButtonShown,
      forceCloseModal,
    } = this.state;
    let { options, parentOptions } = this.props;
    options = options || [];
    parentOptions = parentOptions || [];

    // parent options
    let selectedParentOption = null;
    if (selectedParent) {
      const selectedParentFound = parentOptions.filter(
        o => o.id === selectedParent.id,
      );

      if (selectedParentFound.length > 0) {
        selectedParentOption = {
          label: selectedParent.code || selectedParent.name,
          value: selectedParent,
        };
      }
    }

    const parentOptionList = parentOptions.map(option => {
      option.code = option.code || option.name;
      return {
        label: option.code,
        value: option,
      };
    });

    // child options
    let selectedOption = null;

    if (selectedParent) {
      if (selectedParent.id) {
        options = options.filter(
          type => type.gender_id.toString() === selectedParent.id.toString(),
        );
      } else {
        options = [];
      }

      if (selected) {
        const selectedFound = options.filter(o => o.id === selected.id);

        if (selectedFound.length > 0) {
          selectedOption = {
            label: selected.code || selected.name,
            value: selected,
          };
        }
      }
    }

    const optionList = options.map(option => {
      option.code = option.code || option.name;
      return {
        label: option.code,
        value: option,
      };
    });

    return (
      <Modal
        forceClose={forceCloseModal}
        disabled={disabled}
        size="md"
        buttonText={<i className="fas fa-pen"></i>}
        title={optionManagerTitle}
        className="btn btn-light"
      >
        <div className="form-group m-2">
          <Select
            value={selectedParentOption}
            onChange={o => {
              this.updateParent(o);
            }}
            options={parentOptionList}
            isSearchable={true}
            isClearable={true}
            isDisabled={disabled}
            classNamePrefix="select"
          />
        </div>

        <Tabs defaultActiveKey="create" id="uncontrolled-tab-example">
          {/*ADD*/}
          <Tab eventKey="create" title="Create new item">
            <Row>
              <div className="col">
                <div className="form-group m-2">
                  <Input
                    type="text"
                    name="code"
                    value={toAdd}
                    updateEditorData={value => {
                      this.setOptionToAdd(value);
                    }}
                  />
                </div>
                <div className="form-group m-2 text-center">
                  {isConfirmButtonShown && (
                    <>
                      <span
                        className="btn btn-secondary ml-1"
                        onClick={() => {
                          addOption({
                            code: toAdd,
                            parent_id: selectedParent.id,
                          });
                          this.closeOptionManagerModal();
                        }}
                      >
                        Confirm
                      </span>
                      <span
                        className="btn btn-danger ml-1"
                        onClick={() => {
                          this.toggleConfirmButton();
                        }}
                      >
                        Cancel
                      </span>
                    </>
                  )}

                  {!isConfirmButtonShown && (
                    <span
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        this.toggleConfirmButton();
                      }}
                    >
                      Add option
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Tab>

          {/*UPDATE*/}
          <Tab eventKey="update" title="Update">
            <Row>
              <div className="col">
                <div className="form-group m-2">
                  <Select
                    value={selectedOption}
                    onChange={o => {
                      this.updateData(o);
                    }}
                    options={optionList}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={disabled}
                    classNamePrefix="select"
                  />
                </div>
                <div className="form-group m-2">
                  <Input
                    type="text"
                    name="code"
                    value={toAdd}
                    updateEditorData={value => {
                      this.setOptionToAdd(value);
                    }}
                  />
                </div>
                <div className="form-group m-2 text-center">
                  {isConfirmButtonShown && (
                    <>
                      <span
                        className="btn btn-secondary ml-1"
                        onClick={() => {
                          updOption({
                            code: toAdd,
                            id: selectedOption.value.id,
                            parent_id: selectedParent.id,
                          });
                          this.closeOptionManagerModal();
                        }}
                      >
                        Confirm
                      </span>
                      <span
                        className="btn btn-danger ml-1"
                        onClick={() => {
                          this.toggleConfirmButton();
                        }}
                      >
                        Cancel
                      </span>
                    </>
                  )}

                  {!isConfirmButtonShown && (
                    <span
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        this.toggleConfirmButton();
                      }}
                    >
                      Update option
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Tab>

          {/*DELETE*/}
          <Tab eventKey="delete" title="Delete">
            <Row>
              <div className="col">
                <div className="form-group m-2">
                  <Select
                    value={selectedOption}
                    onChange={o => {
                      this.updateData(o);
                    }}
                    options={optionList}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={disabled}
                    classNamePrefix="select"
                  />
                </div>
                <div className="form-group m-2 text-center">
                  {isConfirmButtonShown && (
                    <>
                      <span
                        className="btn btn-secondary ml-1"
                        onClick={() => {
                          delOption({ id: selectedOption.value.id });
                          this.closeOptionManagerModal();
                        }}
                      >
                        Confirm
                      </span>
                      <span
                        className="btn btn-danger ml-1"
                        onClick={() => {
                          this.toggleConfirmButton();
                        }}
                      >
                        Cancel
                      </span>
                    </>
                  )}

                  {!isConfirmButtonShown && (
                    <span
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        this.toggleConfirmButton();
                      }}
                    >
                      Delete option
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Tab>
        </Tabs>
      </Modal>
    );
  }
}

export default OptionManagerWithParent;
