import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import RCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import utils from '../../helpers/utils';
import $ from 'jquery';

class Calendar extends Component {
  // static defaultProps = {
  //  timeInputHidden: true
  // }
  constructor(props) {
    super(props);

    this.state = {
      date: null,
      hourTime: '',
      timeInputHidden: true,
      showCalendar: false,
    };
  }
  showCalendar() {
    this.setState({
      showCalendar: true,
    });
  }
  componentDidUpdate() {
    $('body').off('click');
    const { showCalendar } = this.state;
    if (showCalendar) {
      setTimeout(() => {
        $('body').on('click', e => {
          const clickedInsideComponent =
            $(e.target).parents('.input-calendar').length > 0 ? true : false;
          if (!clickedInsideComponent) {
            this.setState({
              showCalendar: false,
            });
            $('body').off('click');
          }
        });
      }, 50);
    }
  }
  toggleTime() {
    this.setState({
      timeInputHidden: !this.state.timeInputHidden,
    });
  }
  updDate(d) {
    const { updateEditorData } = this.props;
    const { hourTime } = this.state;
    console.log(d);

    this.setState({
      date: d,
    });

    const splitTime = hourTime.split(':');
    if (splitTime.length >= 2) {
      d.setHours(splitTime[0]);
      d.setMinutes(splitTime[1]);
    }
    console.log(d);
    updateEditorData && updateEditorData(d.toISOString());
  }
  updHour(h) {
    const { updateEditorData } = this.props;
    const { date } = this.state;

    this.setState({
      hourTime: h,
    });

    let d = new Date(date);
    const splitTime = h.split(':');
    if (splitTime.length <= 2) {
      d.setHours(splitTime[0]);
      d.setMinutes(splitTime[1]);

      updateEditorData && updateEditorData(d.toISOString());
    }
  }
  render() {
    let { date, disabled } = this.props;
    const { timeInputHidden, showCalendar } = this.state;
    date = date ? new Date(date) : null;

    return (
      <div>
        <div className="input-group input-calendar">
          <input
            readOnly={disabled}
            type="text"
            className="form-control"
            value={utils.dateToString(date)}
            onFocus={() => {
              this.showCalendar();
            }}
          />

          {!disabled && showCalendar && (
            <div style={{ position: 'absolute', top: '40px', zIndex: 100 }}>
              <RCalendar
                locale="en-GB"
                onChange={val => {
                  this.updDate(val);
                }}
                value={date}
              />
            </div>
          )}

          <div className="ml-1">
            <Button
              disabled={disabled}
              className="btn btn-light"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.toggleTime();
              }}
            >
              {' '}
              <i className="far fa-clock"></i>{' '}
            </Button>
          </div>
        </div>

        {!timeInputHidden && (
          <input
            placeholder="00:00"
            type="text"
            className="form-control mt-1"
            name="enddate"
            value=""
          />
        )}
      </div>
    );
  }
}

export default Calendar;
