import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Modal } from './Button';

// AppLink is a NavLink wrapper that set the App in a Loading state when clicked
class AppLink extends Component {
  static defaultProps = {
    isConfirmationModalEnabled: false,
  };
  setAppInLoadingState() {
    console.log('setAppInLoadingState');
    // TODO
    // connect AppLink to redux store (mapDispatchToProps) -> appInLoading()
    // connect App to redux store (mapStateToProps)
    // connect pages to redux store (mapDispatchToProps) -> appReady()

    // this will allow pages to tell the app to wait while fetching data
  }
  goto() {
    const { history, to } = this.props;
    history.push(to);
  }
  render() {
    const { exact, to, className, isConfirmationModalEnabled, location } =
      this.props;

    let activeClassName = null;
    if (location.pathname.match(to)) {
      activeClassName = 'active';
    }

    return (
      <>
        {!isConfirmationModalEnabled && (
          <li className="nav-item">
            <NavLink
              exact={exact}
              to={to}
              className={className}
              onClick={() => {
                this.setAppInLoadingState();
              }}
            >
              {this.props.children}
            </NavLink>
          </li>
        )}

        {isConfirmationModalEnabled && (
          <Modal
            buttonText={<>{this.props.children}</>}
            title="Discard changes"
            tooltipText="Cancel"
            variant="link"
            className={activeClassName}
            confirm={() => {
              this.goto();
            }}
          >
            <p>Do you really want to discard all your modifications ?</p>
          </Modal>
        )}
      </>
    );
  }
}

export default withRouter(AppLink);
