import React, { Component } from 'react';
import appConfig from '../../appConfig';
import utils from '../../helpers/utils';
import { withRouter, NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import { Editor } from '../../components/Editor';
import { Notification } from '../../components/Core';
import { Input, Select, FileUploader } from '../../components/Input';
import { Avatar } from '../../components/Field';
import { Modal } from '../../components/Button';
import * as AppActions from '../../redux/app/actions';
import * as Actions from '../../redux/user/actions';
import { Planning } from '../../components/Metrics';
import LatestWorkouts from './Metrics/LatestWorkouts';
import SchedulePreference from '../../components/Metrics/SchedulePreference';

const EditorTitle = props => {
  let history = useHistory();
  const { back, item, readOnlyMode } = props;
  return (
    <div className="editor-title">
      {back && (
        <>
          {readOnlyMode && (
            <NavLink to={back}>
              <i className="fas fa-chevron-left"></i>
            </NavLink>
          )}

          {!readOnlyMode && (
            <Modal
              buttonText={<i className="fas fa-chevron-left"></i>}
              title="Quit editor"
              className="back-button"
              confirm={() => {
                history.push(back);
              }}
            >
              <p>
                Do you really want to quit the editor without saving your
                modifications ?
              </p>
            </Modal>
          )}
        </>
      )}
      <h1>
        {item.first_name} {item.last_name}
      </h1>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    actionMessage: state.app.actionMessage,
    loggedInUser: state.app.loggedInUser,
    options: state.options,
    recentlyAdded: state.users.recentlyAdded,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    clearActionMessage: () => dispatch(AppActions.messageClear()),

    updItem: item => dispatch(Actions.updItem(item)),
    delItem: (index, id) => dispatch(Actions.delItem(index, id)),
  };
};

let saveDone = false;
class UserEditor extends Component {
  static defaultProps = {
    readOnlyMode: true,
  };
  constructor(props) {
    super(props);
    this.props.clearActionMessage();

    let readOnlyMode = props.readOnlyMode;
    if (props.item._id === 'new') {
      readOnlyMode = false;
    }

    this.state = {
      item: props.item,
      readOnlyMode: readOnlyMode,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.item._id === 'in-waiting' &&
      this.props.item._id !== 'in-waiting'
    ) {
      this.refreshItem();
    }

    if (saveDone && this.props.item !== this.state.item) {
      // used by UserProfile
      saveDone = false;
      if (this.props.saveCallback) {
        this.props.saveCallback();
      }
    }
  }
  refreshItem() {
    const { item } = this.props;
    this.setState({
      item: item,
    });
  }
  updateData(key, updData) {
    const { item } = this.state;
    let itemUpdated = cloneDeep(item);

    utils.updateObjKey(itemUpdated, key, updData);

    this.setState({
      item: itemUpdated,
    });
  }
  cancel() {
    this.setState({
      item: this.props.item,
      errors: {},
    });

    this.toggleMode(true);
  }
  save() {
    const { item } = this.state;
    this.props.updItem(item);
    saveDone = true;
    this.toggleMode(true);

    if (saveDone && item._id === 'new') {
      setTimeout(() => {
        const { recentlyAdded, history, back } = this.props;
        if (recentlyAdded) {
          this.setState({
            item: recentlyAdded,
          });
          history.push(`${back}/edit/${recentlyAdded._id}`);
        }
      }, 400);
    }
  }
  delete(index, id) {
    const { year, history, back } = this.props;
    this.props.delItem(index, _id);
    setTimeout(() => {
      history.push(back);
    }, 0);
  }
  toggleMode(mode) {
    this.props.clearActionMessage();
    this.setState({
      readOnlyMode: mode,
    });
  }
  render() {
    const { noDeletionAllowed, back, deleteMode } = this.props;
    const { readOnlyMode, item } = this.state;

    const options = {
      role: appConfig.role,
    };

    const Actions = () => (
      <>
        {!noDeletionAllowed && (
          <Modal
            show={deleteMode}
            buttonText={<i className="far fa-trash-alt"></i>}
            title="Deletion confirmation"
            tooltipText="Delete"
            className="btn btn-danger mr-1"
            confirm={() => {
              this.delete(item.id);
            }}
          >
            <p>Do you really want to delete the user ?</p>
            <h5>
              {item.firstname} {item.lastname}
            </h5>
          </Modal>
        )}
      </>
    );

    return (
      <Editor
        header={
          <EditorTitle back={back} item={item} readOnlyMode={readOnlyMode} />
        }
        actions={<Actions />}
        readOnly={readOnlyMode}
        item={item}
        save={() => {
          this.save();
        }}
        cancel={() => {
          this.cancel();
        }}
        toggleMode={mode => {
          this.toggleMode(mode);
        }}
        showActions={false}
      >
        {this.props.actionMessage.message && (
          <Notification
            message={this.props.actionMessage.message}
            statusCode={this.props.actionMessage.code}
          />
        )}

        <form className="user-form mb-3">
          <Row>
            <div className="col-lg-12 mb-2">
              <Planning />
            </div>
            {/* <div className="col-lg-12 mb-2">
              <SchedulePreference />
            </div> */}
          </Row>
          {/* <Row>
            <div className="col-lg-12">
              <div className="card mb-3">
                <div className="card-header">User details</div>
                <div className="card-body">
                  <Row>
                    <div className="form-group col">
                      <label htmlFor="role">Role</label>
                      <Select
                        disabled={readOnlyMode}
                        selected={item.role}
                        options={options.role}
                        updateEditorData={data => {
                          this.updateData('role', data);
                        }}
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Row> */}
        </form>
      </Editor>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserEditor),
);
