import React, { Component } from 'react';
import { Card, Row } from 'react-bootstrap';

class Goal extends Component {
  render() {
    const dayBoxStyle = {
      minHeight: '100px',
    };
    return (
      <Card className="overflow-hidden">
        <div className="card-header d-flex">
          <div className="mr-auto">Planning</div>
          <div className="ml-auto">Button</div>
        </div>
        <div className="card-body p-0">
          <Row>
            <div className="col border border-light text-center">MON</div>
            <div className="col border border-light text-center">TUE</div>
            <div className="col border border-light text-center">WED</div>
            <div className="col border border-light text-center">THU</div>
            <div className="col border border-light text-center">FRI</div>
            <div className="col border border-light text-center">SAT</div>
            <div className="col border border-light text-center">SUN</div>
          </Row>
          <Row>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
            <div
              className="col border border-light text-center"
              style={dayBoxStyle}
            ></div>
          </Row>
        </div>
      </Card>
    );
  }
}

export default Goal;
