import React, { Component, Fragment } from 'react';

class ProtectedContent extends Component {
  renderChildren() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
  render() {
    const { isProtected, contentFallback } = this.props;
    const output = !isProtected ? this.renderChildren() : contentFallback;
    return output;
  }
}
export default ProtectedContent;
