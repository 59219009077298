import React, { Component } from 'react';
import Row from './Row';
import { Loader } from '../Core';
import utils from '../../helpers/utils';
import ScrollContainer from 'react-indiana-drag-scroll';
import $ from 'jquery';
import _ from 'lodash';
import Pagination from './Pagination';

class Table extends Component {
  static defaultProps = {
    items: [],
    searchInput: null,
    currentPage: 1,
    isCard: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      sortByKey: null,
      ascOrder: true,
      currentPage: props.currentPage,
    };

    this.ref = React.createRef();
    this.previousRef = null;
    this.isTheadSticky = false;
    // Timeout used to call the handler a last time to handle quick scrolling to the top of the document
    this.scrollTimeout = null;

    this.sortedFilteredItems = [];

    this.handleThead = this.handleThead.bind(this);
    this.adjustColumnWidth = this.adjustColumnWidth.bind(this);
    this.getSortedFilteredItems = this.getSortedFilteredItems.bind(this);
    this.swipeIndicator = this.swipeIndicator.bind(this);
    this.hideSwipeIndicator = this.hideSwipeIndicator.bind(this);

    this.isSwipeIndicatorActive = false;
  }
  componentDidUpdate(prevProps, prevState) {
    // const {items, searchInput, retrieveItemsShown} = this.props;
    // const {sortByKey, ascOrder } = this.state;
    //
    // if (searchInput !== prevProps.searchInput || sortByKey !== prevState.sortByKey || ascOrder !== prevState.ascOrder) {
    //   if (retrieveItemsShown) {
    //     let filteredItems = utils.itemLookup(items, searchInput);
    //     filteredItems = utils.sortByKey(filteredItems, sortByKey, ascOrder);
    //     retrieveItemsShown(filteredItems);
    //   }
    // }

    // if (this.state.currentPage !== this.props.currentPage) {
    //   this.setState({
    //     currentPage: this.props.currentPage,
    //   });
    // }

    this.adjustColumnWidth();
    this.swipeIndicator();
  }

  getSortedFilteredItems() {
    return this.sortedFilteredItems;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleThead, false);
    window.addEventListener('resize', this.adjustColumnWidth, false);
    window.addEventListener(
      'resize',
      () => {
        this.swipeIndicator();
      },
      false,
    );
    window.addEventListener('scroll', this.hideSwipeIndicator, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleThead, false);
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = null;
    }
    window.removeEventListener('resize', this.adjustColumnWidth, false);
    window.removeEventListener(
      'resize',
      () => {
        this.swipeIndicator();
      },
      false,
    );
    window.removeEventListener('scroll', this.hideSwipeIndicator, false);
  }
  swipeIndicator(move = 0) {
    if (!this.ref.current) {
      return;
    }

    const $ref = $(this.ref.current);
    const $datatableTbody = $ref.find('.datatable-content tbody');
    const $appContent = $('.app-content');
    const $swipeIndicator = $ref.find('.swipe-indicator');

    const containerPaddingLeft = parseInt(
      $appContent.find('.container-fluid').first().css('padding-left'),
    );
    const containerPaddingRight = parseInt(
      $appContent.find('.container-fluid').first().css('padding-right'),
    );
    const appContentAreaWidth =
      $appContent.outerWidth() - (containerPaddingLeft + containerPaddingRight);

    // console.log($datatableTbody.outerWidth(), 'table');
    // console.log( appContentAreaWidth + move, 'page');

    if (appContentAreaWidth + move < $datatableTbody.outerWidth()) {
      this.isSwipeIndicatorActive = true;
      $swipeIndicator.show();
    } else {
      this.isSwipeIndicatorActive = false;
      $swipeIndicator.hide();
    }
  }
  hideSwipeIndicator() {
    if (!this.ref.current) {
      return;
    }

    if (!this.isSwipeIndicatorActive) {
      return;
    }

    const $ref = $(this.ref.current);
    const $datatableContent = $ref.find('.datatable-content');
    const $datatableLastTr = $datatableContent.find('tr:last');
    const $swipeIndicatorWrapper = $ref.find('.swipe-indicator-wrapper');
    const $swipeIndicator = $swipeIndicatorWrapper.find('.swipe-indicator');

    // const scroll = this.isScrolledIntoView($swipeIndicator);
    const swipeIndicatorWrapperPos =
      $swipeIndicatorWrapper.offset().top +
      $swipeIndicatorWrapper.outerHeight() / 2;
    const tableBottomPos =
      $datatableLastTr.offset().top + $datatableLastTr.outerHeight();

    if (swipeIndicatorWrapperPos > tableBottomPos) {
      if ($swipeIndicator.is(':visible')) {
        $swipeIndicator.hide();
      }
    } else {
      if (!$swipeIndicator.is(':visible')) {
        $swipeIndicator.show();
      }
    }
  }
  adjustColumnWidth() {
    if (!this.ref.current) {
      return;
    }

    const $ref = $(this.ref.current);
    const $tableContentTd = $ref.find(
      '.datatable-content table tbody tr:first td',
    );
    const $stickyTheadTh = $ref.find('.datatable-sticky-thead table th');

    // set thead column width according to table content
    let columnWidths = [];
    $tableContentTd.each((i, el) => {
      let $el = $(el);
      columnWidths[i] = $el.outerWidth();
    });
    $stickyTheadTh.each((i, el) => {
      let $el = $(el);
      $el.css('min-width', columnWidths[i]);
    });

    // set table content column width according to thead (in case thead data is larger)
    let theadWidths = [];
    $stickyTheadTh.each((i, el) => {
      let $el = $(el);
      theadWidths[i] = $el.outerWidth();
    });
    $tableContentTd.each((i, el) => {
      let $el = $(el);
      // $el.width(theadWidths[i]);
      $el.css('min-width', theadWidths[i]);
    });
  }

  isScrolledIntoView($elem) {
    if (!$elem.offset()) {
      return null;
    }

    let fixedTopElementHeight = 0;
    $('.fixed-top').each((i, el) => {
      fixedTopElementHeight += $(el).outerHeight();
    });

    var docViewTop = $(window).scrollTop() + fixedTopElementHeight;
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  handleThead() {
    if (!this.ref.current) {
      return;
    }

    const $ref = $(this.ref.current);
    const $datatableContent = $ref.find('.datatable-content');
    const $normalThead = $ref.find(
      '.datatable-content table thead.datatable-normal-thead',
    );
    const $stickyThead = $ref.find('.datatable-sticky-thead');
    const isNormalTheadIntoView = this.isScrolledIntoView($normalThead);

    if (this.ref.current !== this.previousRef) {
      // Adjust column width when ref changes
      this.adjustColumnWidth();
      this.previousRef = this.ref.current;
    }

    if (this.isTheadSticky && isNormalTheadIntoView) {
      this.isTheadSticky = false;
    } else if (!this.isTheadSticky && !isNormalTheadIntoView) {
      this.isTheadSticky = true;
    }

    if (this.isTheadSticky) {
      const datatableInitialPos = $datatableContent.offset()
        ? $datatableContent.offset().top
        : 0;

      let fixedTopElementHeight = 0;
      $('.fixed-top').each((i, el) => {
        fixedTopElementHeight += $(el).outerHeight();
      });

      const topUpd = $(document).scrollTop();
      $stickyThead.css(
        'top',
        topUpd + fixedTopElementHeight - (datatableInitialPos + 3),
      );
      $stickyThead.show();
    } else {
      $stickyThead.hide();
    }

    // Timeout used to call the handler a last time to handle quick scrolling to the top of the document
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(this.handleThead, 50);
  }

  toggleSort(sortKey) {
    if (sortKey === this.state.sortByKey) {
      this.setState({
        ascOrder: !this.state.ascOrder,
      });
    } else {
      this.setState({ sortByKey: sortKey, ascOrder: true });
    }
  }
  onPageClick(pageToGo) {
    // // fetch items for page
    // const start = (pageToGo - 1) * nbItemPerPage;
    // const limit = nbItemPerPage;
    // this.props.fetchChunk(start, limit);
    if (this.props.onPageClick) {
      this.props.onPageClick(pageToGo);
    }

    // this.setState({
    //   currentPage: pageToGo,
    // });
  }
  render() {
    const {
      column,
      items,
      editItem,
      delItem,
      clickItem,
      resetFilters,
      searchInput,
      actions,
      filters,
      header,
      title,
      inLoading,
      isSticky,
      isCard,
      pagination,
      paginationNbItemPerPage,
      paginationItemCount,
      onPageClick,
      currentPage,
    } = this.props;

    const { sortByKey, ascOrder } = this.state;

    let className = this.props.className || '';

    const columnsHeaders = column.map((col, i) => (
      <th
        key={i}
        scope="row"
        onClick={() => {
          this.toggleSort(col.objKey);
        }}
        className={sortByKey === col.objKey ? 'active' : ''}
        style={col.cellStyle}
      >
        <span>
          {col.label !== 'Actions' && (
            <>
              {col.label}

              {sortByKey === col.objKey && (
                <>
                  {' '}
                  {ascOrder && <i className="fas fa-angle-down"></i>}{' '}
                  {!ascOrder && <i className="fas fa-angle-up"></i>}
                </>
              )}

              {sortByKey !== col.objKey && (
                <i className="fas fa-angle-down"></i>
              )}
            </>
          )}
        </span>
      </th>
    ));

    let itemsToShow;
    if (pagination) {
      itemsToShow = items
        ? items.slice(
            (currentPage - 1) * paginationNbItemPerPage,
            (currentPage - 1) * paginationNbItemPerPage +
              paginationNbItemPerPage,
          )
        : [];
    } else {
      itemsToShow = items;
    }

    let filteredItems = utils.itemLookup(itemsToShow, searchInput);
    filteredItems = utils.sortByKey(filteredItems, sortByKey, ascOrder);
    this.sortedFilteredItems = filteredItems;

    // Stat
    const stat = {
      confirmed: filteredItems.filter(event =>
        event ? event.status === 'confirmed' : false,
      ),
      pending: filteredItems.filter(event =>
        event ? event.status === 'pending' : false,
      ),
      cancelled: filteredItems.filter(event =>
        event ? event.status === 'cancelled' : false,
      ),
    };

    const stickyClassname = isSticky ? 'fixed-top' : '';

    const cardClassnames = isCard
      ? { wrapper: 'card', inner: 'card-body' }
      : { wrapper: '', inner: '' };

    return (
      <>
        {' '}
        <div className={cardClassnames.wrapper}>
          <div className={cardClassnames.inner}>
            {inLoading && <Loader />}{' '}
            {!inLoading && (
              <div className={'datatable '} ref={this.ref}>
                <div
                  className={
                    'datatable-top ' + stickyClassname + ' ' + className
                  }
                >
                  <div className="datatable-top-inner">
                    <div className="datatable-title">{title}</div>
                    <div className="datatable-actions">{actions}</div>
                  </div>
                </div>

                {searchInput && (
                  <div className="search-results-title text-center m-3">
                    <h2>Results for "{searchInput}"</h2>
                  </div>
                )}

                {filters}

                <div className="datatable-header">
                  {(stat.confirmed.length > 0 ||
                    stat.pending.length > 0 ||
                    stat.cancelled.length > 0) && (
                    <div className="datatable-stats">
                      <span className="stat">
                        {stat.confirmed.length} confirmed
                      </span>
                      <span className="stat">
                        {stat.pending.length} pending
                      </span>
                      <span className="stat">
                        {stat.cancelled.length} cancelled
                      </span>
                    </div>
                  )}

                  {header}
                </div>

                {_.isEmpty(filteredItems[0]) ? (
                  <></>
                ) : (
                  <ScrollContainer
                    className="scroll-container"
                    vertical={false}
                    onScroll={e => {
                      this.swipeIndicator(e);
                    }}
                    onEndScroll={e => {
                      this.swipeIndicator(e);
                    }}
                  >
                    <div className={'datatable-content sticky-thead'}>
                      <div
                        className="datatable-sticky-thead animated fadeInDown"
                        style={{
                          width: '100%',
                          display: 'none',
                        }}
                      >
                        <table className="table">
                          <thead>
                            <tr>{columnsHeaders}</tr>
                          </thead>
                        </table>
                      </div>

                      <table className="table">
                        <thead className="datatable-normal-thead">
                          <tr>{columnsHeaders}</tr>
                        </thead>

                        <tbody>
                          {filteredItems.map((item, i) => (
                            <Row
                              key={'row-' + i}
                              columnHeader={columnsHeaders}
                              column={column}
                              item={item}
                              clickItem={clickItem}
                              delItem={delItem}
                              editItem={editItem}
                              rowIndex={i}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </ScrollContainer>
                )}
                {filteredItems.length === 0 && !inLoading && (
                  <>
                    {currentPage <= 1 && (
                      <div className="no-content">
                        <p className="text-center">
                          Oops, no events are matching to your filter.
                          <span
                            className="text-primary"
                            onClick={() => {
                              resetFilters && resetFilters();
                            }}
                          >
                            Reset filter
                          </span>
                        </p>
                      </div>
                    )}
                  </>
                )}

                <div className="swipe-indicator-wrapper">
                  <div className="swipe-indicator">
                    <i className="icon-swipe"></i>
                  </div>
                </div>
              </div>
            )}{' '}
          </div>
        </div>
        {pagination && paginationItemCount > 0 && (
          <Pagination
            nbPages={
              paginationItemCount % paginationNbItemPerPage === 0
                ? paginationItemCount / paginationNbItemPerPage
                : paginationItemCount / paginationNbItemPerPage + 1
            }
            currentPage={currentPage}
            onPageClick={page => {
              this.onPageClick(page);
            }}
          />
        )}
      </>
    );
  }
}

export default Table;
