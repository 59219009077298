import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Actions from '../redux/app/actions';
import $ from 'jquery';

const adjustPadding = ref => {
  if (!ref.current) {
    return;
  }

  let fixedTopElementHeight = 0;
  $('.fixed-top').each((i, el) => {
    fixedTopElementHeight += $(el).outerHeight();
  });

  $(ref.current).css('padding-top', fixedTopElementHeight + 30);
};

const mapStateToProps = state => {
  return { searchValue: state.app.searchValue };
};
const mapDispatchToProps = dispatch => {
  return {
    search: value => dispatch(Actions.search(value)),
    disableSidebarConfirmation: value =>
      dispatch(Actions.disableSidebarConfirmation()),
  };
};

class PageLayout extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    // reset search
    this.props.search('');
    this.props.disableSidebarConfirmation();
  }
  // componentDidMount(){
  //   window.addEventListener('resize', ()=>{ adjustPadding(this.ref) }, false);
  // }
  // componentDidUpdate(){
  //   adjustPadding(this.ref);
  // }
  render() {
    const { dark, children } = this.props;
    let { className } = this.props;
    className = className ? ' page-' + className : '';

    const darkBg = dark ? ' bg-dark' : '';

    return (
      <div ref={this.ref} className={'page' + darkBg + className}>
        <Container className="page-container" fluid={false}>
          <div>{children}</div>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
