import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../helpers/Loading';
import appConfig from '../appConfig';
import Dashboard from '../pages/Dashboard';

const pages = [
  'Dashboard',
  'Notification',
  'Monitoring',
  'Events',
  'Users',
  'Admins',
  'Equipments',
  'Exercises',
  'Programmes',
  'Recovery',
  'Docs',
  'Tools',
  'Logout',
  'tools/Categories',
  'tools/CustomFields',
  'tools/Tokens',
  'Profile',
  'Documentation',
  'Error_404',
];

const asyncRoutes = pages.map(page =>
  Loadable({
    loader: () => import('../pages/' + page),
    loading: Loading,
  }),
);

const getAsyncRoute = pageName => {
  const index = pages.indexOf(pageName);
  return asyncRoutes[index];
};

export default props => (
  <Fragment>
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>

      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/monitoring" component={getAsyncRoute('Monitoring')} />
      <Route
        exact
        path="/notification"
        component={getAsyncRoute('Notification')}
      />

      <Route exact path="/profile" component={getAsyncRoute('Profile')} />
      <Route path="/documentation" component={getAsyncRoute('Documentation')} />

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/users" component={getAsyncRoute('Users')} />
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/admins" component={getAsyncRoute('Admins')} />
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/equipments" component={getAsyncRoute('Equipments')} />
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/exercises" component={getAsyncRoute('Exercises')} />
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/programmes" component={getAsyncRoute('Programmes')} />
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/recovery" component={getAsyncRoute('Recovery')} />
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <Route path="/docs" component={getAsyncRoute('Docs')} />
      )}

      {/*   
      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route path="/tools/categories" component={ getAsyncRoute('tools/Categories') } />
      }

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route path="/tools/custom-fields" component={ getAsyncRoute('tools/CustomFields') } />
      }

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route path="/tools/tokens" component={ getAsyncRoute('tools/Tokens') } />
      }

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route exact path="/tools" component={ getAsyncRoute('Tools') } />
      }
*/}

      <Route component={getAsyncRoute('Error_404')} />
    </Switch>
  </Fragment>
);
