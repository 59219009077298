import utils from '../../helpers/utils';

const actions = [
  'FETCH_EXERCISES',
  'FETCH_EXERCISES_BEGIN',
  'FETCH_EXERCISES_SUCCESS',
  'FETCH_EXERCISES_ERROR',
];

export default utils.redux.createActionTypes('OPTIONS', actions);
