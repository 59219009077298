import React, { Component } from 'react';
import Searchbar from '../containers/Searchbar';
import { Container } from 'react-bootstrap';
import { User } from './Field';
import { Dropdown } from './Button';
import AppLink from './AppLink';
import { Modal } from './Button';

class AppHeader extends Component {
  render() {
    const { toggleButton, loggedInUser, logout, isSidebarConfirmationEnabled } =
      this.props;

    // console.log(loggedInUser, 'loggedInUser');

    return (
      <header className="app-header fixed-top">
        <Container fluid={false}>
          <div className="inner">
            {toggleButton}

            <Searchbar />
          </div>
        </Container>
      </header>
    );
  }
}

export default AppHeader;
