import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import utils from '../../helpers/utils';

const imgStyle = {
  width: '100%',
  height: '150px',
  objectFit: 'cover',
  objectPosition: '50% 50%',
  backgroundColor: '#fff',
};

const MediaLibraryItem = props => {
  return (
    <div className="media-item">
      <Card
        border={
          props.selectedItem !== undefined &&
          props.selectedItem._id === props._id
            ? 'primary'
            : undefined
        }
      >
        {props.thumbnailUrl && props.type.match(/image/) && (
          <Card.Img variant="top" src={props.thumbnailUrl} style={imgStyle} />
        )}

        {props.thumbnailUrl && props.type.match(/video/) && (
          <video style={imgStyle}>
            <source src={props.thumbnailUrl} type={props.type} />
            Your browser does not support the video tag.
          </video>
        )}

        {props.type.match(/video/) && (
          <div className="text-right mt-1 mr-1">
            <span className="badge badge-info">VIDEO</span>
          </div>
        )}

        {props.type.match(/image/) && (
          <div className="text-right mt-1 mr-1">
            <span className="badge badge-success">IMAGE</span>
          </div>
        )}

        {(props.name || props.description) && (
          <Card.Body>
            <Card.Title>{props.name}</Card.Title>
            <Card.Text>{props.description}</Card.Text>
            <div>
              {props.size && (
                <div>
                  <small>{utils.formatBytes(props.size)}</small>
                </div>
              )}
              {props.createdAt && (
                <div>
                  <small>{utils.formatDate(props.createdAt)}</small>
                </div>
              )}
            </div>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default MediaLibraryItem;
