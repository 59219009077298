import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Mock from './Mock';
import rootReducer from '../reducers';
import auth from '../_middleware/auth';

const initialState = {
  // options: Mock.options,
};

// const initialState = {
//   events: [],
//   users: [],
//   logs: [],
// };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, auth)),
);

export default store;
window.store = store;
