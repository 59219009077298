import React, { Component } from 'react';
import AppNavigation from './AppNavigation';
import logo from '../assets/imgs/alpi-training-logo.svg';
import { Modal } from './Button';
import { Admin } from './Field';
import appConfig from '../appConfig';
import { Dropdown } from './Button';
import AppLink from './AppLink';

class AppSidebar extends Component {
  render() {
    const { loggedInUser, logout, isSidebarConfirmationEnabled } = this.props;
    return (
      <aside className="app-sidebar">
        <div className="app-logo">
          <figure>
            <img src={logo} alt="Alpi Training Dashboard" />
          </figure>
        </div>

        <div className="mt-auto mb-auto">
          <AppNavigation
            loggedInUser={loggedInUser}
            isSidebarConfirmationEnabled={isSidebarConfirmationEnabled}
          >
            <li>
              {!isSidebarConfirmationEnabled && (
                <button
                  href="#"
                  className="nav-link"
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="fas fa-sign-out-alt"></i> Sign out
                </button>
              )}

              {isSidebarConfirmationEnabled && (
                <Modal
                  buttonText={
                    <>
                      <i className="fas fa-sign-out-alt"></i> Sign out
                    </>
                  }
                  title="Discard changes"
                  tooltipText="Cancel"
                  variant="link"
                  confirm={() => {
                    logout();
                  }}
                >
                  <p>Do you really want to discard all your modifications ?</p>
                </Modal>
              )}
            </li>
          </AppNavigation>
        </div>
        <div className="mt-auto mb-3 ml-auto mr-auto">
          <Dropdown
            // label="open"
            variant="link"
            direction="up"
            label={
              <Admin
                avatar={loggedInUser.avatar}
                email={loggedInUser.email}
                roles={loggedInUser.roles}
              />
            }
          >
            {(loggedInUser.roles.includes(appConfig.role[0]) ||
              loggedInUser.roles.includes(appConfig.role[1])) && (
              <AppLink
                to={`/admins/${loggedInUser._id}`}
                className="nav-link"
                isConfirmationModalEnabled={isSidebarConfirmationEnabled}
              >
                <i className="fas fa-user"></i> My account
              </AppLink>
            )}
            {(loggedInUser.roles.includes(appConfig.role[0]) ||
              loggedInUser.roles.includes(appConfig.role[1])) && (
              <AppLink
                to="/admins"
                exact
                className="nav-link"
                isConfirmationModalEnabled={isSidebarConfirmationEnabled}
              >
                <i className="fas fa-users-cog"></i> Administrators
              </AppLink>
            )}
          </Dropdown>
        </div>
      </aside>
    );
  }
}

export default AppSidebar;
