import React, { Component } from 'react';
import FileUploader from './FileUploader';
import { Loader } from '../Core';

const LOGO_STATUS = {
  NONE: 1,
  VALID: 2,
  INVALID: 3,
};

class LogoUploader extends Component {
  static defaultProps = {
    imageUrl: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      isImagePreviewShown: false,
      isValidImage: LOGO_STATUS.NONE,
    };
  }
  showPreview() {
    this.setState({
      isImagePreviewShown: true,
    });
  }
  hidePreview() {
    this.setState({
      isImagePreviewShown: false,
    });
  }
  updateData(data) {
    const { updateEditorData, minWidth, minHeight } = this.props;

    const onValidData = () => {
      updateEditorData && updateEditorData(data);

      this.setState({
        isValidImage: LOGO_STATUS.VALID,
      });
    };

    const onInvalidData = () => {
      this.setState({
        isValidImage: LOGO_STATUS.INVALID,
        isImagePreviewShown: false,
      });
    };

    if (data.type && !data.type.startsWith('image/')) {
      // Without the timeout, the image preview is shown
      setTimeout(onInvalidData, 0);
      return;
    }

    if (minWidth && minHeight) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const img = new Image();

        img.onload = () => {
          if (img.width < minWidth || img.height < minHeight) {
            onInvalidData();
            return;
          }

          onValidData();
        };

        img.src = fileReader.result;
      };

      fileReader.readAsDataURL(data);
    } else {
      onValidData();
    }
  }
  deleteData() {
    const { deleteEditorData } = this.props;

    deleteEditorData && deleteEditorData();
  }
  componentDidUpdate(prevProps) {
    // Reinit the isValidImage state when toggling read/edit mode.
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({
        isValidImage: LOGO_STATUS.NONE,
      });
    }
    //   const { imageUrl } = this.props;
    //   const { isImagePreviewShown } = this.state
    //   if((!prevProps.imageUrl && imageUrl) && !isImagePreviewShown){
    //     this.showPreview()
    //   }
  }
  render() {
    const { disabled, deleteEditorData, minWidth, minHeight } = this.props;
    let { imageUrl } = this.props;
    let { isImagePreviewShown, isValidImage } = this.state;
    if (imageUrl) {
      isImagePreviewShown = false;
    }
    // imageUrl = imageUrl || '';

    const LogoImage = ({ imageUrl }) => {
      const showLoader = imageUrl === '' && disabled;

      return (
        <>
          {imageUrl && imageUrl !== '' && (
            <img
              className="card-img-top"
              src={imageUrl}
              alt="Eventlogo"
              style={{ minWidth: '100%', width: '100%', height: 'auto' }}
            />
          )}

          {showLoader && <Loader mini={true} />}
        </>
      );
    };

    const isDefaultLogo = imageUrl.match('/default/event-logo.jpg')
      ? true
      : false;
    const showRemoveText =
      (!isDefaultLogo && imageUrl !== '') || isImagePreviewShown;

    return (
      <div className="logo-upload card">
        {/*readOnly mode*/}
        {disabled && (
          <div className="mb-1">
            <LogoImage imageUrl={imageUrl} />
          </div>
        )}

        {/*editor mode*/}
        {!disabled && (
          <>
            <div className="mb-1">
              <LogoImage imageUrl={imageUrl} />

              {isImagePreviewShown && (
                <img
                  className="preview img-responsive"
                  src={''}
                  alt="logo-preview"
                  style={{ minWidth: '100%', width: '100%', height: 'auto' }}
                />
              )}

              {!isImagePreviewShown && (
                <>
                  {isValidImage === LOGO_STATUS.INVALID && (
                    <div
                      className="alert alert-light small"
                      role="alert"
                    >{`Updated logo is invalid. Must be at least ${minWidth}/${minHeight}px. Supported format: JPG`}</div>
                  )}
                  {!imageUrl && imageUrl === '' && (
                    <>
                      <FileUploader
                        selector=".logo-upload img.preview"
                        updateEditorData={data => {
                          this.updateData(data[0]);
                          this.showPreview();
                        }}
                      />
                      <div className="info text-center m-1 small">
                        <div>{`Logo is published to Longines.com and Longinestiming.com (Minimum size requirement: ${minWidth}/${minHeight}px. Supported format: JPG)`}</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="actions">
              {isDefaultLogo && imageUrl !== '' && (
                <span
                  className="card-link"
                  onClick={() => {
                    deleteEditorData();
                    this.hidePreview();
                  }}
                >
                  Upload logo
                </span>
              )}

              {showRemoveText && (
                <span
                  className="card-link"
                  onClick={() => {
                    deleteEditorData();
                    this.hidePreview();
                  }}
                >
                  Remove
                </span>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default LogoUploader;

// <div className="logo-upload card">
// { (!imageUrl || imageUrl === '') && disabled &&
//   <div className="text-center">No logo</div>
// }
//
//
// { (imageUrl && imageUrl !== '') && isImagePreviewShown &&
//   <img className="card-img-top" src={imageUrl} alt="Event name logo" />
//
// }
//
// { (!disabled && !isImagePreviewShown) &&
//   <FileUploader selector=".logo-upload img" updateEditorData={(data)=>{ this.updateData(data[0]) }}  />
// }
//
// <div className="card-body">
//   { !disabled  && imageUrl &&
//     <a href="#" className="card-link" onClick={()=>{ deleteEditorData() }}>Remove</a>
//   }
// </div>
//
// </div>
