// export default [
//   {
//     label: 'Profile',
//   },
//   {
//     label: 'Workouts',
//   },
//   {
//     label: 'Actions',
//     meta: 'User',
//   },
// ];

export default [
  {
    label: 'Email',
    objKey: 'email',
  },
  {
    label: 'Role',
    objKey: 'roles',
  },
  {
    label: 'Creation Date',
    objKey: 'createdAt',
  },
  // {
  //   label: 'Profile last update',
  //   objKey: 'profile.updatedAt',
  // },
];
