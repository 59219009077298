// authentication middleware
import appConfig from '../../appConfig';
import utils from '../../helpers/utils';
import axios from '../../axiosConfig';

const API_URL = appConfig.api_url;
const TOKEN_KEY = appConfig.settings.tokenKey || 'app-key:api_token';

const refreshToken = cb => {
  axios({
    method: 'post',
    url: `${API_URL}/auth/refresh`,
  })
    .then(response => {
      let data = response.data;
      cb(data);
    })
    .catch(error => {
      console.log(error);
    });
};

const authentication = store => next => action => {
  const timestamp = new Date().getTime() / 1000;
  const token = sessionStorage.getItem(TOKEN_KEY);

  if (!token || token === 'null') {
    return next(action);
  }

  if (action.type === 'APP_LOGIN_SUCCESS') {
    return next(action);
  }

  const t = utils.parseJwt(token);
  return next(action);

  // if (t.exp < timestamp) {
  //   console.log('TOKEN EXPIRED');
  //   refreshToken((data, error) => {
  //     const newToken = data.access_token;
  //     sessionStorage.setItem(TOKEN_KEY, newToken);

  //     return next(action);
  //   });
  // } else {
  //   return next(action);
  // }
};

export default authentication;
