import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class AlertModal extends Component {
  // handleShow(){
  //   this.setState({
  //     show: true
  //   })
  //   if(this.props.openCallback){
  //     this.props.openCallback();
  //   }
  // }
  // handleClose(){
  //   this.setState({
  //     show: false
  //   })
  //   if(this.props.closeCallback){
  //     this.props.closeCallback();
  //
  //     // Have to use a time out since the modal restores the focus on the element that triggered it
  //     // Modal onExited event can't be used because a Transition component must be used to trigger the event
  //     window.setTimeout(() => {
  //       document.activeElement.blur();
  //     }, 0);
  //   }
  // }
  cancel() {
    const { cancel } = this.props;
    cancel && cancel();
    // this.handleClose();
  }
  confirm() {
    const { confirm } = this.props;
    confirm && confirm();
    // this.handleClose();
  }
  render() {
    const { show, size, title, confirmButtonText, children } = this.props;
    const confirmButtonTextData = confirmButtonText
      ? confirmButtonText
      : 'Confirm';

    return (
      <>
        <Modal
          size={size}
          centered
          show={show}
          onHide={() => {
            this.cancel();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{children}</Modal.Body>
          {this.props.confirm && (
            <Modal.Footer>
              <Button
                variant="default"
                onClick={() => {
                  this.cancel();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  this.confirm();
                }}
              >
                {confirmButtonTextData}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}

export default AlertModal;
