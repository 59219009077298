import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import { DataViewer } from '../Field';

// let isCurrentClassNameFound;
class Row extends Component {
  static defaultProps = {
    provided: {},
  };
  render() {
    const {
      column,
      item,
      clickItem,
      delItem,
      editItem,
      rowIndex,
      provided,
      innerRef,
    } = this.props;

    let isHighlightedClassname = null;
    if (item.last_updated) {
      const lastUpdated = new Date(item.last_updated);
      const dateDiff = new Date();
      dateDiff.setDate(dateDiff.getDate() - 7);

      if (lastUpdated > dateDiff) {
        isHighlightedClassname = 'is-highlighted';
      }
    }

    let baseData = cloneDeep(item);
    const columns = column.map((col, i) => {
      // build data obj
      let data = baseData;
      if (col.objKey) {
        col.objKey.split('.').forEach(key => {
          if (data) {
            data = data[key];
          }
        });
      }

      return (
        <DataViewer
          key={'dataviewer-' + i}
          objKey={col.objKey}
          label={col.label}
          meta={col.meta}
          data={data}
          cellStyle={col.cellStyle}
          delItem={delItem}
          editItem={editItem}
          rowIndex={rowIndex}
        />
      );
    });

    if (!item.exercise) {
      item.exercise = {};
    }

    if (item._id || item.exercise) {
      return (
        <tr
          ref={innerRef}
          className={isHighlightedClassname}
          data-end={item.end_date}
          key={item._id}
          onClick={() => {
            clickItem && clickItem(item, rowIndex);
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {columns}
        </tr>
      );
    } else {
      return null;
    }
  }
}

export default Row;
