import React, { Component } from 'react';
import utils from '../../helpers/utils';

class Input extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
  };
  updateData(value) {
    const { updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  render() {
    const { disabled, name, type, className, required, error } = this.props;
    let { value } = this.props;
    // value = !utils.isEmpty(value) ? value : '';

    return (
      <>
        <div className="input-group">
          <input
            required={required}
            disabled={disabled}
            type={type}
            className={'form-control ' + className}
            name={name}
            value={value}
            onChange={e => {
              this.updateData(e.target.value);
            }}
          />
          {value && !disabled && (
            <div className="input-group-append">
              {/* 
                <button className="btn btn-outline-secondary" type="button">
                Button
                </button> 
              */}
              <span
                className="btn btn-light ml-1 mr-1"
                onClick={() => {
                  this.updateData('');
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </span>
            </div>
          )}
        </div>

        {value && (
          <div className="d-flex mt-2">
            <iframe
              width="360"
              height="210"
              src={'https://www.youtube.com/embed/' + value}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            {!disabled && (
              <div>
                <span
                  class="btn btn-light ml-1 mr-1"
                  onClick={() => {
                    this.updateData('');
                  }}
                >
                  <i class="fas fa-trash-alt"></i>
                </span>
              </div>
            )}
          </div>
        )}

        {error && (
          <>
            {error.map(e => (
              <div className="pt-1">
                <span className="text-danger">{e}</span>
                <br />
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}

export default Input;
