import React, { Component } from 'react';
import { ScrollTop } from '../Control';
import { Modal } from '../Button';
import { AlertModal } from '../Common';
import { User } from '../Field';
import ConditionalTooltip from '../Button/ConditionalTooltip';

class Editor extends Component {
  static defaultProps = {
    readOnly: true, //  'read-only' || 'editor'
    allowEdition: true,
    showActions: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      lockedByModalOpen: false,
    };
  }
  openLockedByModal() {
    this.setState({
      lockedByModalOpen: true,
    });
  }
  closeLockedByModal() {
    this.setState({
      lockedByModalOpen: false,
    });
  }
  toggleMode() {
    const { readOnly } = this.props;

    this.props.toggleMode(!readOnly);
    this.openLockedByModal();
  }
  cancel() {
    // this.toggleMode();
    this.props.cancel();
  }
  save() {
    // this.toggleMode();
    this.props.save();
  }
  render() {
    const { children, actions, showActions, header, item, readOnly, lockedBy } =
      this.props;
    let { lockedByModalOpen } = this.state;

    const isLocked = lockedBy && lockedBy !== 'me';

    return (
      <div className="editor">
        <ScrollTop />
        <div className="editor-header fixed-top">
          {header}

          {showActions && (
            <div className="actions">
              {readOnly && (
                <>
                  {this.props.toggleMode && (
                    <ConditionalTooltip tooltipText="Edit">
                      <button
                        className="ml-auto btn btn-primary mr-1"
                        onClick={() => {
                          this.toggleMode();
                        }}
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    </ConditionalTooltip>
                  )}

                  {isLocked && (
                    <>
                      {/*<h1 style={{position : 'absolute', top: '50px'}}>LOCKED</h1>*/}
                      <AlertModal
                        show={lockedByModalOpen}
                        title="Oops"
                        cancel={() => {
                          this.closeLockedByModal();
                        }}
                        confirm={() => {
                          this.closeLockedByModal();
                        }}
                      >
                        <p className="text-center">
                          This event is currently edited by
                        </p>
                        <div className="d-flex justify-content-center">
                          <User
                            avatar={lockedBy.avatar}
                            name={lockedBy.name}
                            role={lockedBy.role}
                          />
                        </div>
                      </AlertModal>
                    </>
                  )}

                  {actions}
                </>
              )}

              {!readOnly && (
                <>
                  {item.id !== '' && item.id !== 'new' && (
                    <Modal
                      buttonText={<i className="fas fa-undo"></i>}
                      title="Discard changes"
                      tooltipText="Cancel"
                      className="back-button"
                      confirm={() => {
                        this.cancel();
                      }}
                    >
                      <p>
                        Do you really want to discard all your modifications ?
                      </p>
                    </Modal>
                  )}
                  <ConditionalTooltip tooltipText="Save">
                    <button
                      type="button"
                      className="btn btn-success mr-1"
                      onClick={() => {
                        this.save();
                      }}
                    >
                      <i className="far fa-save"></i>
                    </button>
                  </ConditionalTooltip>
                </>
              )}
            </div>
          )}
        </div>

        <div className="editor-children">{children}</div>
      </div>
    );
  }
}

export default Editor;
