import React, { Component } from 'react';
import Avatar from './Avatar';

class WorkoutsOverview extends Component {
  static defaultProps = {
    isActive: true,
  };
  render() {
    const { avatar, name, role, size, isActive } = this.props;

    return (
      <div className="workouts">
        <div className="workout">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout current-day">
          <div className="workout-badge workout-done"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
        <div className="workout">
          <div className="workout-badge"></div>
        </div>
      </div>
    );
  }
}

export default WorkoutsOverview;
