import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from './actionTypes';
import appConfig from '../../appConfig';

const initialState = {
  exercises: [],
};
let updOptionState;

const OptionReducer = (optionState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_EXERCISES_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.exercises = action.payload;
      return updOptionState;

    default:
      return optionState;
  }
};

export default OptionReducer;
