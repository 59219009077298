let config = {
  version: '0.12.0',
  // api_url: 'http://192.168.1.70:8001/api/v1',
  // api_url: 'http://localhost:8001/api/v1',
  // api_url: 'https://api.alpitraining.com/api/v1',
  // api_url: 'https://v2.alpitraining.com/api/v1',
  api_url: 'https://v5.alpitraining.com/api/v1',
  // api_url: 'https://api.staging.alpi-training.ag1.dev/api/v1',
  // api_url: 'https://api.features.alpi-training.ag1.dev/api/v1',
  status: ['Draft', 'Published'],
  statusTableFilter: ['All', 'Draft', 'Review', 'Published'],
  role: ['Admin', 'Analyst', 'Atlhete'],
  defaultLanguage: 'fr',
  languages: ['fr', 'en', 'es'],
  colors: {
    confirmed: '#05C322',
    pending: '#F78E00',
    cancelled: '#AF0000',
  },
  settings: {
    nbItemPerPage: 20,
    tokenKey: 'alpi-training:api_token',
    userKey: 'alpi-training:user',
  },
  statusJsonSearch: {
    All: null,
    Draft: { status: 'Draft' },
    Review: { status: 'Review' },
    Published: { status: 'Published' },
  },
};

// switch (process.env.NODE_ENV) {
//   case 'production':
//     config.api_url = 'https://api.features.alpi-training.ag1.dev/api/v1';
//     break;

//   default:
//     break;
// }

export default config;
