import React, { Component } from 'react';
import Select from 'react-select';
import { Modal } from '../../components/Button';
import { Input } from '../../components/Input';
import { Row, Tabs, Tab } from 'react-bootstrap';
import utils from '../../helpers/utils';
import appConfig from '../../appConfig';

class MultiSelectObj extends Component {
  // constructor(props){
  //   super(props);
  // }
  updateData(data) {
    const { updateEditorData } = this.props;

    const selectedOption = data ? data.map(option => option.value) : null;
    // const selectedOption = data ? data : null;
    console.log(selectedOption, 'selectedOption');
    updateEditorData && updateEditorData(selectedOption);
  }
  render() {
    const { disabled } = this.props;
    let { selected, options } = this.props;
    selected = selected || [];
    options = options || [];

    // remove selected options from options
    options = options.filter(o => !selected.find(ob => o._id == ob._id));

    // sort options
    options = utils.sortByKey(options, 'code', true, true);

    const selectedOption = selected.map(option => ({
      label:
        typeof option.name === 'object'
          ? option.name[appConfig.defaultLanguage] !== ''
            ? option.name[appConfig.defaultLanguage]
            : option.name['en']
          : option.name,
      value: option,
    }));

    const optionList = options.map(option => {
      return {
        label:
          typeof option.name === 'object'
            ? option.name[appConfig.defaultLanguage]
            : option.name,
        value: option,
      };
    });

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto" style={{ width: '100%' }}>
            <Select
              value={selectedOption}
              onChange={o => {
                this.updateData(o);
              }}
              options={optionList}
              isSearchable={true}
              isClearable={true}
              isDisabled={disabled}
              classNamePrefix="select"
              isMulti={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default MultiSelectObj;
