import React, { Component } from 'react';

class Status extends Component {
  // static defaultProps = {}
  render() {
    const { status } = this.props;

    const classNames = {
      Draft: 'badge-info',
      Review: 'badge-warning',
      Published: 'badge-success',
    };

    return <span className={'badge ' + classNames[status]}>{status}</span>;
  }
}

export default Status;
