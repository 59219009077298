import appConfig from '../../appConfig';
import ActionTypes from './actionTypes';
import axios from '../../axiosConfig';
import * as AppActions from '../app/actions';

const limit = 1000;

const API_URL = appConfig.api_url;
const ENTITY_URL = 'exercises';
const ENTITY = 'Exercice';

export const fetchOptionExercices = () => dispatch => {
  dispatch(fetchOptionExercicesBegin());
  axios
    .get(`${API_URL}/${ENTITY_URL}?limit=${1000}&fields=${'name'}`)
    .then(response => {
      let items = response.data.data;
      dispatch(fetchOptionExercicesSuccess(items));
    })
    .catch(error => dispatch(fetchOptionExercicesError(error)));
};
export const fetchOptionExercicesBegin = () => ({
  type: ActionTypes.FETCH_EXERCISES_BEGIN,
  payload: null,
});
export const fetchOptionExercicesSuccess = data => ({
  type: ActionTypes.FETCH_EXERCISES_SUCCESS,
  payload: data,
});
export const fetchOptionExercicesError = error => {
  console.log(error, 'fetchOptionExercicesError');
  return {
    type: ActionTypes.FETCH_EXERCISES_ERROR,
    payload: error,
  };
};

// export const fetchAll = () => dispatch => {
//   dispatch(fetchAllBegin());
//   const requests = [
//     axios.get(`${API_URL}/countries`),
//     axios.get(`${API_URL}/cities`),
//     axios.get(`${API_URL}/events/genders`),
//     axios.get(`${API_URL}/events/types`),
//     axios.get(`${API_URL}/events/classifications`),
//     axios.get(`${API_URL}/events/classes`),
//     axios.get(`${API_URL}/events/marketing`),
//     axios.get(`${API_URL}/events/years`),
//   ];

//   Axios.all(requests)
//     .then(responses => {
//       const options = {
//         country: responses[0].data.data,
//         city: responses[1].data.data,
//         gender: responses[2].data.data,
//         type: responses[3].data.data,
//         classification: responses[4].data.data,
//         class: responses[5].data.data,
//         marketing: {
//           management_presence: responses[6].data.data.filter(
//             item => item.type === 'management_presence',
//           ),
//           visual: responses[6].data.data.filter(item => item.type === 'visual'),
//           watch: responses[6].data.data.filter(item => item.type === 'watch'),
//           ambassadors: responses[6].data.data.filter(
//             item => item.type === 'ambassadors',
//           ),
//           partnership: responses[6].data.data.filter(
//             item => item.type === 'partnership',
//           ),
//           longines_timing_specificities: responses[6].data.data.filter(
//             item => item.type === 'longines_timing_specificities',
//           ),
//         },
//         year: responses[7].data.data,
//       };

//       dispatch(fetchAllSuccess(options));
//     })
//     .catch(error => dispatch(fetchAllError(error)));
// };
// export const fetchAllBegin = () => ({
//   type: ActionTypes.FETCH_ALL_BEGIN,
//   payload: null,
// });
// export const fetchAllSuccess = data => ({
//   type: ActionTypes.FETCH_ALL_SUCCESS,
//   payload: data,
// });
// export const fetchAllError = error => ({
//   type: ActionTypes.FETCH_ALL_ERROR,
//   payload: error,
// });
