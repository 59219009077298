import React, { Component } from 'react';
import utils from '../../helpers/utils';
import appConfig from '../../appConfig';
import Avatar from './Avatar';
import User from './User';
import WorkoutsOverview from './WorkoutsOverview';
import ItemActions from './ItemActions';
import Status from './Status';
import { MultiBadge } from '../Field';

class DataViewer extends Component {
  // static defaultProps = {
  //  stats: []
  // }
  render() {
    const {
      label,
      objKey,
      meta,
      data,
      cellStyle,
      delItem,
      editItem,
      rowIndex,
    } = this.props;

    if (typeof data === 'object' && data && meta == 'json') {
      return <td style={cellStyle}>{utils.toJSON(data)}</td>;
    }

    if (objKey === 'user' && data) {
      return (
        <td style={cellStyle}>
          <User
            avatar={data.avatar}
            name={data.name}
            isActive={data.is_active}
            size="sm"
          ></User>
        </td>
      );
    }

    if (objKey === 'roles' && data) {
      return <td style={cellStyle}>{data.join(', ')}</td>;
    }

    if (label === 'Profile' && !objKey) {
      return (
        <td style={cellStyle}>
          <User
            avatar={data.avatar}
            name={data.name + ' ' + data.lastName}
            isActive={data.is_active}
            size="sm"
          />
        </td>
      );
    }

    if (label === 'Workouts' && !objKey) {
      return (
        <td style={cellStyle}>
          <WorkoutsOverview />
        </td>
      );
    }

    if (label === 'Inventory' || meta === 'object array') {
      return (
        <td style={cellStyle}>
          {data &&
            data.map((o, key) => (
              <span key={key} className="badge badge-light ml-2">
                {o.name[appConfig.defaultLanguage]}
              </span>
            ))}
        </td>
      );
    }

    if (meta === 'video' && data) {
      return (
        <td style={cellStyle}>
          {data.video && (
            <>
              {data.video.fr && (
                <a
                  className="btn btn-light"
                  target="_blank"
                  href={data.video.fr.smallUrl}
                >
                  <i className="fas fa-external-link-alt"></i>
                </a>
              )}
            </>
          )}

          {!data.video && <> - </>}
        </td>
      );
    }

    if (meta === 'string array') {
      return (
        <td style={cellStyle}>
          {data &&
            data.map((str, key) => (
              <span key={key} className="badge badge-light ml-2">
                {str}
              </span>
            ))}
        </td>
      );
    }

    if (label === 'Actions' && !objKey && meta === 'ItemActions') {
      return (
        <td style={cellStyle}>
          <ItemActions data={data} />
        </td>
      );
    }

    if (label === 'Actions' && !objKey && meta === 'User') {
      return (
        <td style={cellStyle}>
          <ItemActions data={data} />
        </td>
      );
    }

    if (label === 'Actions' && !objKey && meta === 'Doc') {
      return (
        <td style={cellStyle}>
          <ItemActions data={data} />
        </td>
      );
    }

    if (label === 'Actions' && !objKey && meta === 'Programme') {
      return (
        <td style={cellStyle}>
          <ItemActions
            data={data}
            delItem={delItem}
            editItem={editItem}
            rowIndex={rowIndex}
          />
        </td>
      );
    }

    if (
      objKey === 'marketing.management_presence' ||
      objKey === 'marketing.watch' ||
      objKey === 'marketing.visual' ||
      objKey === 'marketing.ambassadors'
    ) {
      return (
        <td style={cellStyle}>
          <MultiBadge disabled={true} showAdder={false} selected={data} />
        </td>
      );
    }

    if (label === 'Avatar') {
      return (
        <td style={cellStyle}>
          <Avatar avatar={data}></Avatar>
        </td>
      );
    }

    if (label === 'Name') {
      return (
        <td style={cellStyle} className="item-name">
          <strong>{data}</strong>
        </td>
      );
    }

    if (label === 'Status') {
      return (
        <td style={cellStyle} className="item-status">
          <Status status={data} />
        </td>
      );
    }

    if (
      label === 'Creation Date' ||
      label === 'Last update' ||
      label === 'Profile last update'
    ) {
      return (
        <td style={cellStyle} className="item-date">
          {utils.formatDate(data)}
        </td>
      );
    }

    if (label === 'From' || label === 'To') {
      return (
        <td style={cellStyle} className="item-date">
          {utils.formatEventDate(data)}
        </td>
      );
    }

    if (data && label === 'Country') {
      return (
        <td style={cellStyle} className="item-country">
          <div className="country d-flex">
            <div className="flag">
              <span
                className={'flag-icon flag-icon-' + data.toLowerCase()}
              ></span>
            </div>
            <div className="code">{data}</div>
          </div>
        </td>
      );
    }

    if (typeof data === 'object' && data) {
      if (data.code) {
        return <td style={cellStyle}>{data.code}</td>;
      }

      return <td style={cellStyle}>{utils.toJSON(data)}</td>;
    }

    return <td style={cellStyle}>{data}</td>;
  }
}

export default DataViewer;
