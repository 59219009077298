import cloneDeep from 'lodash/cloneDeep';
import utils from '../../helpers/utils';
import ActionTypes from './actionTypes';

const initialState = {
  collection: [],
  count: 0,
  users: {},
  workouts: {},
  // recentlyAdded: null,
  // tablePage: 1,
};

const ItemReducer = (itemsState = initialState, action) => {
  let updState = cloneDeep(itemsState);
  switch (action.type) {
    case ActionTypes.CLEAR_COLLECTION:
      updState.collection = [];
      updState.count = 0;
      // updState.tablePage = 1;

      return updState;

    case ActionTypes.FETCH_USERS_SUCCESS:
      updState.users = action.payload;

      return updState;

    case ActionTypes.FETCH_WORKOUTS_SUCCESS:
      updState.workouts = action.payload;

      return updState;

    default:
      return itemsState;
  }
};

export default ItemReducer;
