import utils from '../../helpers/utils';

const actions = [
  'FETCH_CHUNK',
  'FETCH_CHUNK_BEGIN',
  'FETCH_CHUNK_SUCCESS',
  'FETCH_CHUNK_ERROR',

  'CLEAR_COLLECTION',
  'SET_TABLE_PAGE',
  'SET_TABLE_CATEGORY',

  'ADD_ITEM',
  'DEL_ITEM',
  'UPD_ITEM',
];

export default utils.redux.createActionTypes('PROGRAMME', actions);
