import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class Checkbox extends Component {
  // static defaultProps = {}
  updateData(value) {
    const { updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  render() {
    const { name, label, value, checked } = this.props;

    return (
      <div
        onClick={e => {
          this.updateData(value);
        }}
      >
        <Form.Check
          type="radio"
          label={`${label}`}
          name={name}
          value={value}
          checked={checked}
          onChange={e => {
            this.updateData(e.target.value);
          }}
        />
      </div>
    );
  }
}

export default Checkbox;
