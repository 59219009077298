import cloneDeep from 'lodash/cloneDeep';
import utils from '../../helpers/utils';
import ActionTypes from './actionTypes';

const initialState = {
  collection: [],
  count: null,
  recentlyAdded: null,
  tablePage: 1,
  tableCategory: null,
};

const ItemReducer = (itemsState = initialState, action) => {
  let updState = cloneDeep(itemsState);
  switch (action.type) {
    case ActionTypes.SET_TABLE_PAGE:
      updState.tablePage = action.payload;

      return updState;

    case ActionTypes.SET_TABLE_CATEGORY:
      updState.tableCategory = action.payload;

      return updState;

    case ActionTypes.CLEAR_COLLECTION:
      updState.collection = [];
      updState.count = null;
      // updState.tablePage = 1;

      return updState;

    case ActionTypes.FETCH_CHUNK_BEGIN:
      updState.count = null;

      return updState;

    case ActionTypes.FETCH_CHUNK_SUCCESS:
      updState.collection = utils.redux.collectionUpdate(
        updState.collection,
        action.payload,
        action.meta.start || 0,
      );

      updState.count = action.count;

      return updState;

    case ActionTypes.ADD_ITEM:
      updState.recentlyAdded = action.payload;

      // store in collection
      // updState.collection.push(action.payload);
      updState.collection.unshift(action.payload);
      updState.tablePage = 1;

      return updState;

    case ActionTypes.DEL_ITEM:
      const id = action.payload;
      updState.collection = utils.redux.collectionDeleteId(
        updState.collection,
        action.payload,
      );

      return updState;

    case ActionTypes.UPD_ITEM:
      updState.recentlyAdded = null;
      const update = utils.redux.collectionUpdateItem(
        updState.collection,
        action.payload,
      );

      updState.collection = update.collection;
      if (update.action == 'add') {
        updState.tablePage = 1;
        updState.recentlyAdded = action.payload;
      }

      return updState;

    default:
      return itemsState;
  }
};

export default ItemReducer;
