import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';

class Pill extends Component {
  updateFilterData() {
    const { updateEditorData, items, item } = this.props;

    let updItems;
    if (items) {
      updItems = cloneDeep(items);

      if (typeof item === 'string') {
        updItems = updItems.filter(el => el !== item);
      } else {
        updItems = updItems.filter(el => el.id !== item.id);
      }

      updItems = updItems.length > 0 ? updItems : null;
    } else {
      updItems = item;
    }

    updateEditorData && updateEditorData(updItems);
  }
  render() {
    const { children } = this.props;
    return (
      <span className="filter-badge badge badge-light mr-1">
        {children}
        <i
          className="ml-1 fas fa-times"
          onClick={() => {
            this.updateFilterData();
          }}
        ></i>
      </span>
    );
  }
}

export default Pill;
